import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ContentManagement from "./pages/contentManagement/content-management";
import JobRequest from "./pages/JobRequest";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { ProtectedRoute } from "./config/services/protectedRoute.js";
import JobRequestDetail from "./pages/JobRequestDetail";
import AutoCompleteRequest from "./pages/autoCompleteRequest";
import AppLogout from "./components/logout/applogout";
import Blog from "./pages/blog/Blog";
import GlobalStyle from "./GlobalStyle";
import BlogPost from "./pages/blog/BlogPost";
import PreviewBlog from "./pages/blog/PreviewBlog";
import { useSelector } from "react-redux";
import { USER_TYPE } from "./config/constants/defaultValues";
import SystemConfiguration from "./pages/system/systemConfiguration";
import BlogConfiguration from "./pages/blogManagement/blogConfiguration";


function App() {
  return (
    <>
      <GlobalStyle />
      <div className="App">
        {
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        }
      </div>
    </>
  );
}

function Dashboard() {
  const { userType } = useSelector((state) => state.auth);

  return (
    <AppLogout>
      <Main>
        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          {(userType === USER_TYPE.SUPER_ADMIN || userType === USER_TYPE.JUMP_ADMIN) && <>
            <Route
              path="/jobRequest"
              element={
                <ProtectedRoute>
                  <JobRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/jobRequest/JobDetails/:jobID"
              element={
                <ProtectedRoute>
                  <JobRequestDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/AutoCompleteRequest"
              element={
                <ProtectedRoute>
                  <AutoCompleteRequest />
                </ProtectedRoute>
              }
            />
          </>}
          {(userType === USER_TYPE.SUPER_ADMIN || userType === USER_TYPE.BLOG_ADMIN) && <>
            <Route
              path="/blog"
              element={
                <ProtectedRoute>
                  <Blog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/BlogPost"
              element={
                <ProtectedRoute>
                  <BlogPost />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PreviewBlog"
              element={
                <ProtectedRoute>
                  <PreviewBlog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/blog-management"
              element={
                <ProtectedRoute>
                  <BlogConfiguration />
                </ProtectedRoute>
              }
            />

          </>}
          {(userType === USER_TYPE.SUPER_ADMIN || userType === USER_TYPE.SYSTEM_ADMIN) && <>
            <Route
              path="/contentmanagement"
              element={
                <ProtectedRoute>
                  <ContentManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/system-configuration"
              element={
                <ProtectedRoute>
                  <SystemConfiguration />
                </ProtectedRoute>
              }
            />
          </>}


        </Routes>
      </Main>
    </AppLogout>
  );
}

export default App;
