import { Col, Row} from "antd";
import { JobRequestStyle } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getJobApprovalRequestDataRequest } from "../redux/jobApprovalRequestSlice";
import { STATUSES } from "../config/constants/defaultValues";
import Loading from "../components/shared/GeneralComponents";
import { Pagination } from "antd";
import Search from "antd/lib/input/Search";
import JobRequestListTile from "../components/shared/JobRequestListTile";
import {
  SearchOutlined,
} from "@ant-design/icons";

const JobRequest = () => {
  const dispatch = useDispatch();
  const { status, jobApprovalData, error } = useSelector(
    (state) => state.jobApprovalRequest
  );
  const [search, setSearch] = useState();

  useEffect(() => {
    dispatch(getJobApprovalRequestDataRequest({}));
  }, [dispatch]);

  const paginationOnChange = (page) => {
    // jobApprovalData.meta.page = page;
    dispatch(
      getJobApprovalRequestDataRequest({ generalSearch: search, page: page })
    );
  };

  const onSearch = (generalSearch) => {
    setSearch(generalSearch);
    dispatch(getJobApprovalRequestDataRequest({ generalSearch, page: 1 }));
  };

  return (
    <>
      {status === STATUSES.LOADING ? (
        <Loading />
      ) : status === STATUSES.ERROR ? <div>{error}</div> : (
        <JobRequestStyle>
          <div className="layout-content">
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                <div className="project-ant pb-3">
                  <div className="padding-left-6">
                    <div className="heading">Job Approval Request</div>
                  </div>

                  <div>
                    <Search
                      onSearch={onSearch}
                      onChange={(value) => setSearch(value.value)}
                      prefix={<SearchOutlined width={"20px"} />}
                      allowClear
                      placeholder="Search"
                      value={search}
                      style={{
                        width: "300",
                        
                      }}
                    />
                  </div>
                </div>
                <JobRequestListTile jobApprovalList={jobApprovalData?.data} />
              </Col>
            </Row>
          </div>

          {
            <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
              <Pagination
                current={jobApprovalData?.meta.page ?? 1}
                total={jobApprovalData?.meta.total}
                showSizeChanger={false}
                onChange={paginationOnChange}
              />
            </div>
          }
        </JobRequestStyle>
      )}
    </>
  );
};

export default JobRequest;
