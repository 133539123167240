import React from "react";
import { JobRequestListTileStyle } from "./style";
import { Avatar, List } from "antd";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

const JobRequestListTile = ({ jobApprovalList }) => {
  return (
    <>
      <JobRequestListTileStyle>
        <List
          itemLayout="horizontal"
          dataSource={jobApprovalList}
          renderItem={(job) => (
            <Link to={`/jobRequest/JobDetails/${job?.id ?? ""}`}>
              <List.Item>
                <div className="row w-100 align-items-center px-3">
                  <div className="col-md-5">
                    <div className="d-flex align-items-center justify-content-start pl-3">
                      <Avatar
                        className="shape-avatar"
                        shape="circle"
                        size={80}
                        src={job?.job_company.logo ?? ""}
                      ></Avatar>
                      <div style={{ width: "80%" }}>
                        <h6 className="list-heading">
                          {job?.job_title.name ?? ""}
                        </h6>

                        <p className="mb-0 sub-heading">
                          {job?.job_company.name ?? ""}
                        </p>
                        <p className="mb-0 sub2-heading">
                          {job?.job_locations[0].location.location ?? ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="span-main-style">
                        {" "}
                        Job category{""}{" "}
                        <span className="job-category">&#x2022;</span>
                        <span className="job-category">
                          {" "}
                          {job?.category.name ?? ""}
                        </span>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="job-time mr-3">
                        {job?.created_at ?? ""}
                      </span>
                      <RightOutlined />
                    </div>
                  </div>
                </div>
              </List.Item>
            </Link>
          )}
        />
      </JobRequestListTileStyle>
    </>
  );
};
export default JobRequestListTile;
