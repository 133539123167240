import React from "react";
import { SideBarTabWrapped } from "./style";
import BaseInput from "../../components/form/BaseInput";
import { SearchOutlined } from "@ant-design/icons";

const SideBarTab = ({ title, subTitle, isSeeker }) => {
  return (
    <>
      <SideBarTabWrapped>
        <div className="side-tabs">
          {/* <div className="m-0">
            <BaseInput
              className="search_style"
              name="generalSearch"
              placeText="Company name"
              inputIcon={<SearchOutlined />}
              req={false}
            />
          </div> */}
          <div
            className="nav nav_side_tab nav-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active col"
              id="v-pills-home-tab"
              aria-controls="v-pills-home"
              data-bs-target="#v-pills-home"
              data-bs-toggle="pill"
              type="button"
              role="tab"
              aria-selected="true"
            >
              <span> Home</span>
            </button>
            <button
              className="nav-link mr-3"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              <span>Contact Page</span>
            </button>

            <button
              className="nav-link"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              <span>About Us</span>
            </button>
            <button
              className="nav-link"
              id="privacy-tab"
              data-bs-toggle="pill"
              data-bs-target="#privacy"
              type="button"
              role="tab"
              aria-controls="privacy"
              aria-selected="false"
            >
              <span>Privacy Policy</span>
            </button>
            <button
              className="nav-link"
              id="picture-tab"
              data-bs-toggle="pill"
              data-bs-target="#picture"
              type="button"
              role="tab"
              aria-controls="picture"
              aria-selected="false"
            >
              <span>Picture Policy</span>
            </button>
            <button
              className="nav-link col"
              id="terms-tab"
              data-bs-toggle="pill"
              data-bs-target="#terms"
              type="button"
              role="tab"
              aria-controls="terms"
              aria-selected="false"
            >
              <span>Terms of Service</span>
            </button>
          </div>
        </div>
      </SideBarTabWrapped>
    </>
  );
};
export default SideBarTab;
