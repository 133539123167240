// import { useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { USER_TYPE } from "../../config/constants/defaultValues";
import { ReactComponent as SettingIcon } from "../../assets/images/icon/setting.svg";
import { ReactComponent as BlogIcon } from "../../assets/images/icon/blogIcon.svg";
import { ReactComponent as JobIcon } from "../../assets/images/icon/jobIcon.svg";
import { ReactComponent as ContentIcon } from "../../assets/images/icon/contentIcon.svg";
import { ReactComponent as AutoIcon } from "../../assets/images/icon/autoIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/icon/dashboardIcon.svg";

function Sidenav({ color }) {
  const { userType } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");


  return (
    <>
      {/* <div className="brand">
        <a href="https://jumprecruiter.com/">
          <img src={logo} alt="" />
        </a>
      </div>
      <hr /> */}
      <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
        {
          <Menu.Item key="1">
            <NavLink to="/dashboard">
              {/* <span className="icon">{dashboard}</span> */}
              <span
                className="icon"
                style={{
                  background: page === "profile" ? color : "",
                }}
              >
                {/* <i class="bi bi-briefcase"></i> */}
                <DashboardIcon />
              </span>
              <span className="label">Dashboard</span>
            </NavLink>
          </Menu.Item>
        }
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.SYSTEM_ADMIN) && (
            <Menu.Item key="2">
              <NavLink className="item-icon" to="/contentmanagement">
                <span
                  className="icon"
                  style={{
                    background: page === "profile" ? color : "",
                  }}
                >
                  {/* <i class="bi bi-briefcase"></i> */}
                  <ContentIcon />
                </span>
                <span className="label">Content Management</span>
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="3">
              <NavLink to="/jobrequest">
                <span
                  className="icon"
                  style={{
                    background: page === "profile" ? color : "",
                  }}
                >
                  <JobIcon />
                </span>
                <span className="label">Job Approval Request</span>
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="4">
              <NavLink to="/AutoCompleteRequest">
                <span
                  className="icon"
                  style={{
                    background: page === "profile" ? color : "",
                  }}
                >
                  {/* <i className="bi bi-arrow-up-left-square"></i> */}
                  <AutoIcon />
                </span>
                <span className="label">Auto Complete Request</span>
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.BLOG_ADMIN) && (
            <Menu.Item key="5">
              <NavLink to="/blog">
                <span
                  className="icon"
                  style={{
                    background: page === "profile" ? color : "",
                  }}
                >
                  {/* <i class="bi bi-pencil-square"></i> */}
                  <BlogIcon />
                </span>
                <span className="label">Blog</span>
              </NavLink>
            </Menu.Item>
          )}

        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.SYSTEM_ADMIN) && (
            <Menu.Item key="7">
              <NavLink className="item-icon" to="/system-configuration">
                <span
                  className="icon"
                  style={{
                    background: page === "profile" ? color : "",
                  }}
                >
                  <SettingIcon />
                  {/* <img src={SettingIcon} alt="Not Found!!" fill='red' /> */}
                </span>
                <span className="label">System Configuration</span>
                <span
                  className="icon"
                  style={{
                    background: page === "tables" ? color : "",
                    marginRight: "0px",
                    paddingLeft: "20px",
                  }}
                >
                  <RightOutlined />
                </span>
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.BLOG_ADMIN) && (
            <Menu.Item key="8">
              <NavLink to="/blog-management">
                <span
                  className="icon"
                  style={{
                    background: page === "profile" ? color : "",
                  }}
                >
                  {/* <i class="bi bi-pencil-square"></i> */}
                  <BlogIcon />
                </span>
                <span className="label">Blog Page Management</span>
              </NavLink>
            </Menu.Item>
          )}
      </Menu>
    </>
  );
}

export default Sidenav;
