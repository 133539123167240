import React from "react";
import { Input, Form } from "antd";
import styled from "styled-components";
const BaseInputWrapped = styled.div`
  label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .w-100 {
    margin: 0px;
    padding-bottom: 30px;
  }
  .style_input {
    color: rgba(100, 100, 100, 1);
    .ant-input {
      height: 40px;
      color: rgba(100, 100, 100, 1);
      font-weight: 400;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        height: 40px;
      }
    }
  }
`;
const BaseInput = ({
  placeText,
  inputIcon,
  labelText,
  name,
  sizes,
  req,
  onChange,
  addon,
  marginBottom,
  disabled,
  pattern,
  patternMessage,
  type,
  typeMessage,
  maxLength,
  inputType,
  isFocusElem,
}) => {
  return (
    <BaseInputWrapped>
      <Form.Item
        name={name || "name"}
        label={labelText || ""}
        className={marginBottom ? `${marginBottom} w-100` : "w-100 labelText"}
        rules={[
          {
            required: req === false ? false : true,
            message: "This field is required!!",
          },
          {
            pattern: pattern || "",
            message: patternMessage || "",
          },
          {
            type: type || "string",
            message: typeMessage || "",
          },
        ]}
      >
        <Input
          ref={isFocusElem}
          addonAfter={addon || ""}
          prefix={inputIcon || ""}
          placeholder={placeText || "Type Here"}
          size={sizes || "large"}
          onChange={onChange}
          disabled={disabled || false}
          autoComplete='off'
          maxLength={maxLength || 500}
          type={inputType || "text"}
          className='style_input'
        />
      </Form.Item>
    </BaseInputWrapped>
  );
};

export default BaseInput;
