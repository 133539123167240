import { useEffect } from "react";

import {
  Row,
  Col,
  Button,
} from "antd";
import logo from "../../assets/images/logod.png";
import logoutImage from "../../assets/images/logoutButton.png";
import { useNavigate } from "react-router-dom";
import { successNotification } from "../notifications/alertNotification";
import { setLogout } from "../../redux/authSlice";





const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ placement, name, subName, onPress }) {

  useEffect(() => window.scrollTo(0, 0));
  const navigate = useNavigate();

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6} xs={12}>
          <div className="brand">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
        </Col>
        <Col span={24} md={18} xs={12} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          <span
            to="/sign-in"
            className="btn-sign-in"
            onClick={() => {
              localStorage.removeItem("user");
              setLogout();
              successNotification("Successfully Logged Out!");
              navigate("/signin");
            }}
          >
            <img src={logoutImage} alt="" height={"30px"} />
          </span>
        </Col>
      </Row>
    </>
  );
}

export default Header;
