import { Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { HomeStyle } from "./style";
// Images
import { useDispatch, useSelector } from "react-redux";
import { STATUSES, USER_TYPE } from "../config/constants/defaultValues";
import { useEffect } from "react";
import { getDashboardRequest } from "../redux/dashboardSlice";
import Loading from "../components/shared/GeneralComponents";
import JobApproval from "../components/shared/JobApproval";
import DashboardCounterCard from "../components/shared/dashboardCounterCard";
import AutoCompleteSetup from "../components/shared/AutoCompleteSetup";

const Home = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { status, dashboardData, error } = useSelector(
    (state) => state.dashboard
  );
  const { userType } = useSelector((state) => state.auth);


  useEffect(() => {
    dispatch(getDashboardRequest());
  }, [dispatch]);

  const countSeeker = [
    {
      isSeeker: true,
      count: dashboardData?.avg_registered_job_seekers ?? 0,
      title: "Total Registered Job Seekers",
      subTitle: "Last 30 days",
    },
    {
      isSeeker: true,

      count: dashboardData?.avg_login_job_seekers ?? 0,
      title: "Total Logged In Job Seekers",
      subTitle: "Last 30 days",
    },
    {
      isSeeker: true,

      count: dashboardData?.avg_job_applied_users ?? 0,
      title: "Total Job Applications",
      subTitle: "Last 30 days",
    },
  ];
  const countEmployer = [
    {
      isSeeker: false,
      count: dashboardData?.avg_registered_employers ?? 0,
      title: "Total Registered Employers",
      subTitle: "Last 30 days",
    },
    {
      isSeeker: false,
      count: dashboardData?.avg_login_employers ?? 0,
      title: "Total Logged In Employers",
      subTitle: "Last 30 days",
    },
    {
      isSeeker: false,
      count: dashboardData?.avg_job_posts ?? 0,
      title: "Total Job Posts",
      subTitle: "Last 30 days",
    },
  ];
  return (
    <>
      {status === STATUSES.LOADING ? (
        <Loading />
      ) : status === STATUSES.ERROR ? (
        <div>{error}</div>
      ) : (
        <HomeStyle>
          <div className="layout-content">
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {countSeeker.map((item, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  className="mb-12"
                >
                  <DashboardCounterCard {...item} />
                </Col>
              ))}
              {countEmployer.map((item, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12} nm
                  xl={8}
                  className="mb-12 padding_left_right"
                >
                  <DashboardCounterCard {...item} />
                </Col>
              ))}
            </Row>
            {(userType === USER_TYPE.SUPER_ADMIN || userType === USER_TYPE.JUMP_ADMIN) && <Row className="rowgap-vbox" gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                <div className="project-ant pb-3">
                  <div className="padding-left-6">
                    <Title className="heading" level={5} >
                      Job Approval Request
                    </Title>
                  </div>
                </div>
                <div className="table-responsive">
                  {dashboardData?.jobs.map((job, index) => (
                    <JobApproval
                      imageUrl={job.job_company?.logo}
                      jobTitle={job.job_title?.name}
                      companyName={job.job_company?.name}
                      postTime={job.created_at}
                      id={job.id}
                      key={index}
                    />
                  ))}
                  <div className="style-view">
                    <Link to="/jobrequest" className="btn-style-view">
                      View All
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                <div className="project-ant pb-3">
                  <div className="padding-left-6">
                    <Title className="heading" level={5}>
                      Auto Complete Approval Request
                    </Title>
                  </div>
                </div>
                <div className="table-responsive">
                  {dashboardData?.setup_auto_completes.map((setup, index) => (
                    <AutoCompleteSetup
                      jobTitle={setup.value}
                      companyName={setup.model_name}
                      postTime={setup.created_at}
                      viewUrl="/dashboard"
                      key={index}
                    />
                  ))}

                  <div className="style-view">
                    <Link to="/AutoCompleteRequest" className="btn-style-view">
                      View All
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>}
          </div>
        </HomeStyle>
      )}
    </>
  );
};

export default Home;
