import React, { useEffect, useState } from "react";
import { SystemConfigurationWrapped } from "./style";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import SystemConfigurationDetail from "./systemConfigurationDetail";
import { getSystemConfigurationDetailRequest, getSystemConfigurationListRequest } from "../../redux/systemConfigurationSlice";
import SystemConfigurationSideBar from "./systemConfigurationSideBar";

const SystemConfiguration = () => {
  const dispatch = useDispatch();

  const { systemConfigurationListData, systemConfigurationListStatus, systemConfigurationListError } = useSelector(
    (state) => state.systemConfiguration
  );

  useEffect(() => {
    dispatch(getSystemConfigurationListRequest({}));
  }, [dispatch]);

  useEffect(() => {
    if (systemConfigurationListData) {
      dispatch(getSystemConfigurationDetailRequest({ modelData: systemConfigurationListData[0] }));
    }
  }, [dispatch, systemConfigurationListData]);

  return (
    <>
      {systemConfigurationListStatus === STATUSES.LOADING ? (
        <Loading />
      ) : systemConfigurationListStatus === STATUSES.ERROR ? (
        <div>{systemConfigurationListError}</div>
      ) : (
        <SystemConfigurationWrapped>
          <div className="layout-content">

            <div className="row">
              <div className="col-sm-3">
                <SystemConfigurationSideBar systemConfigurationDataList={systemConfigurationListData ?? []} />
              </div>
              <div className="col-sm-9">
                <div className="tab-content" id="v-pills-tabContent">
                  <SystemConfigurationDetail />
                </div>
              </div>
            </div>
          </div>
        </SystemConfigurationWrapped>
      )}
    </>
  );
};

export default SystemConfiguration;
