export const LOGIN_API = "/login";
export const DASHBOARD_API = "/admin/dashboard";
export const JOB_APPROVAL_API = "/admin/job-approvals";
export const JOB_APPROVAL_Request_API = "/admin/job-approval-request";
export const GET_CONTENT_MANAGEMENT_PAGES = "/admin/pages/";
export const CONTACT_PAGE_API = "/admin/contact-pages/1";
export const HOME_PAGE_API = "/admin/configurable-items/1";
export const GET_BLOG_LIST_API = "https://jumprecruiter.co.uk/wp-json/wp/v2/posts";
export const GET_BLOG_MANAGEMENT_LIST_API = "/admin/get-blog-page-sections";
export const GET_BLOG_MANAGEMENT_DETAIL_API = "/admin/blog-page-sections/";
export const BLOG_API = "/admin/posts";
export const CATEGORY_API = "/admin/categories_list";
export const AUTO_COMPLETE_APPROVAL_API = "/admin/auto-complete-approvals";
export const AUTO_COMPLETE_ALL_READY_USED_API =
  "/admin/auto-complete-already-used/";
export const AUTO_COMPLETE_ACCEPT_REJECT_API =
  "/admin/auto-complete-approval-request";
export const GET_SYSTEM_CONFIGURATION_LIST_API = "/admin/setup-drop-downs";
export const GET_SYSTEM_CONFIGURATION_DETAIL_API = "/admin/drop-down-list-data";
export const POST_SYSTEM_CONFIGURATION_DETAIL_API =
  "/admin/drop-down-update-data";
