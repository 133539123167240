import React from 'react';
import { Input, Form } from 'antd';

const PasswordInput = ({
  placeText,
  inputIcon,
  labelText,
  name,
  placetype,
  passwordPolicyData,
  maxLength,
  marginBottom,
}) => {
  const handlePasswordPaste = e => {
    e.preventDefault();
    return false;
  };
  return (
    <>
      <Form.Item
        name={name || 'name'}
        label={labelText || ''}
        className={marginBottom ? `${marginBottom} w-100` : 'w-100 mb-4'}
        rules={[
          {
            required: true,
            message: 'This field is required!!',
          },
          {
            pattern: passwordPolicyData?.regex || '',
            message: passwordPolicyData?.regex_message || '',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              } else {
                return Promise.reject('Password does not match');
              }
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          prefix={inputIcon || ''}
          type={placetype || ''}
          placeholder={placeText || 'Type Here'}
          maxLength={maxLength || 20}
          onPaste={handlePasswordPaste}
          
        />
      </Form.Item>
    </>
  );
};

export default PasswordInput;
