import styled from "styled-components";

const ContentWrapped = styled.div`
  padding: 0px 0px 0px 90px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
`;
const HomeWrapped = styled.div`
  padding: 0px 0px 0px 90px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
  .section {
    padding-bottom: 15px;
  }
`;
export { ContentWrapped, HomeWrapped };
