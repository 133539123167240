import React, { useEffect, useState } from "react";
import { SystemConfigurationWrapped } from "../system/style";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/shared/GeneralComponents";
import { STATUSES } from "../../config/constants/defaultValues";
import BlogConfigurationDetail from "./blogConfigurationDetail";
import BlogConfigurationSideBar from "./blogConfigurationSideBar";
import { getBlogManagementDetailRequest, getBlogManagementListRequest } from "../../redux/blogSlice";

const BlogConfiguration = () => {
  const dispatch = useDispatch();

  const { blogManagementListData, blogManagementListStatus, blogManagementListError } = useSelector(
    (state) => state.blog
  );

  useEffect(() => {
    dispatch(getBlogManagementListRequest({}));
  }, [dispatch]);

  useEffect(() => {
    if (blogManagementListData?.data?.data) {
      dispatch(getBlogManagementDetailRequest({ blog: blogManagementListData.data.data[0] }));
    }
  }, [dispatch, blogManagementListData?.data?.data]);

  return (
    <>
      {blogManagementListStatus === STATUSES.LOADING ? (
        <Loading />
      ) : blogManagementListStatus === STATUSES.ERROR ? (
        <div>{blogManagementListError}</div>
      ) : (
        <SystemConfigurationWrapped>
          <div className="layout-content">

            <div className="row">
              <div className="col-sm-3">
                <BlogConfigurationSideBar blogManagementDataList={blogManagementListData?.data?.data ?? []} />
              </div>
              <div className="col-sm-9">
                <div className="tab-content" id="v-pills-tabContent">
                  <BlogConfigurationDetail />
                </div>
              </div>
            </div>
          </div>
        </SystemConfigurationWrapped>
      )}
    </>
  );
};

export default BlogConfiguration;
