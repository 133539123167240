// export const SERVER_URL = "http://192.168.18.23:85/api";
// export const SERVER_URL = "https://dev.sm24-7.com/api";
export const SERVER_URL = "https://dev.jumprecruiter.com/api";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
});

export const USER_TYPE = Object.freeze({
  SUPER_ADMIN: "3",
  SYSTEM_ADMIN: "4",
  JUMP_ADMIN: "5",
  BLOG_ADMIN: "6",
  NONE: "0",
});

export const REQUEST_METHOD = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
});
export const CONTENT_PAGES = Object.freeze({
  ABOUT_US: 1,
  PRIVACY_POLICY: 2,
  PICTURE_POLICY: 3,
  TERMS_OF_SERVICE: 4,
});
export const TableDataState = Object.freeze({
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
});

