import React from "react";

const HeadingTitle = ({ title }) => {
  return (
    <>
      <div className="my-4">
        <div className="heading">{title}</div>
      </div>
    </>
  );
};
export default HeadingTitle;
