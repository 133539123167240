import React, { useEffect, useState } from "react";
import { ContentManagementWrapped } from "../style";
import SideBarTabBlog from "../../components/tabs/SideBarTabBlog";
import BlogDetails from "./BlogDetails";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogDetailRequest, getBlogListRequest } from "../../redux/blogSlice";
import { STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";

const Blog = () => {
  const dispatch = useDispatch();

  const { blogListData, blogListStatus, blogListError } =
    useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(getBlogListRequest({}));
  }, [dispatch]);

  useEffect(() => {
    if (blogListData) {
      dispatch(getBlogDetailRequest({ blogID: blogListData[0]?.id }));
    }
  }, [dispatch, blogListData]);
  return (
    <>
      {
        blogListStatus === STATUSES.LOADING ? <Loading />
          : blogListStatus === STATUSES.ERROR ? <div>{blogListError}</div> : <ContentManagementWrapped>
            <div className="layout-content">
              {/* <div className="row">
                <div className="col-sm-12 d-flex justify-content-end pb-3">
                  <Link className="approved_btn" to="/BlogPost">
                    Post a Blog
                  </Link>
                </div>
              </div> */}
              <div className="row">
                <div className="col-sm-3">
                  <SideBarTabBlog blogSideData={blogListData ?? []} />
                </div>
                <div className="col-sm-9">
                  <div className="tab-content" id="v-pills-tabContent">
                    <BlogDetails />
                  </div>
                </div>
              </div>
            </div>
          </ContentManagementWrapped>
      }
    </>
  );
};

export default Blog;
