import React from "react";
import { BlogWrapped } from "./style";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";


const PreviewBlog = () => {
  const location = useLocation();
  return (
    <>
      <BlogWrapped>
        <div className="row">
          <div className="col-sm-12">
            <div className="tab_section">
              <h1 className="blog_heading">
                {location?.state?.heading ?? ""}
              </h1>
              <p className="text_lightGray_color mb-4">
                {location?.state?.categoryName ?? ""}
              </p>
              <h3 className="secondary_color">Tags</h3>
              <div className="card_right my-1">
                {location?.state?.tag_ids.map((item, index) => (
                  <div className="tags_card" key={index}>
                    {item?? ""}
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-10">
                  <div className="banner_blog_style mt-5">
                    <img
                      src={location?.state?.banner_media ?? ""}
                      alt="Not Found!!"
                    />
                  </div>
                </div>

              </div>

              <div className="mt-5 text-break">
                {ReactHtmlParser(location?.state?.description ?? "")}
              </div>
            </div>
          </div>
        </div>
      </BlogWrapped>

    </>
  );
};

export default PreviewBlog;
