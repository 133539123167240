import React from "react";
import { Form, Menu } from "antd";
import { SideBarBlogTabWrapped } from "./style";
import BaseInput from "../form/BaseInput";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  getBlogDetailRequest,
  getBlogListRequest,
} from "../../redux/blogSlice";

const SideBarTabBlog = ({ blogSideData }) => {
  const dispatch = useDispatch();
  const getBlogDetail = (blogID) => {
    dispatch(getBlogDetailRequest({ blogID }));
  };
  const onFinish = (values) => {
    dispatch(getBlogListRequest({ searchText: values.generalSearch }));
  };
  return (
    <>
      <SideBarBlogTabWrapped>
        <div className="side-tabs">
          <div className="m-0">
            <Form onFinish={onFinish}>
              <BaseInput
                className="search_style"
                name="generalSearch"
                placeText="Search"
                inputIcon={<SearchOutlined />}
                req={false}
              />
            </Form>
          </div>
          <div>
            <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]}>
              {blogSideData.map((blog, index) => (
                <Menu.Item key={index} onClick={() => getBlogDetail(blog.id)}>
                  <span className="label">{blog.title.rendered}</span>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </div>
      </SideBarBlogTabWrapped>
    </>
  );
};
export default SideBarTabBlog;
