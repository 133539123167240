import React from "react";
import { BlogWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../config/constants/defaultValues";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  InstapaperShareButton,
} from "react-share";
import Twitter from "../../assets/images/icon/tw.png";
import Facebook from "../../assets/images/icon/f.png";
import Linkedin from "../../assets/images/icon/in.png";
import Insta from "../../assets/images/icon/inst.png";
import Copy from "../../assets/images/icon/copy.png";
import Loading from "../../components/shared/GeneralComponents";
import ReactHtmlParser from "react-html-parser";
import { Dropdown, Menu, Space, Tooltip } from "antd";
import { deleteBlogRequest, getBlogDetailRequest, getBlogListRequest } from "../../redux/blogSlice";
import { useNavigate } from "react-router-dom";

const BlogDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const { blogDetailData, blogDetailStatus, blogDetailError } = useSelector(
    (state) => state.blog
  );

  // const menu = (
  //   <Menu className="p-2">
  //     <Menu.Item
  //       key="edit"
  //       className="pl-3 pr-5 primary_color"
  //       style={{ fontSize: "16px", fontWeight: "400" }}
  //       onClick={() => {
  //         navigate("/BlogPost", { state: true })
  //       }}

  //     >
  //       Edit
  //     </Menu.Item>
  //     {/* <Menu.Item
  //       key="publish"
  //       className="pl-3 pr-5 primary_color"
  //       style={{ fontSize: "16px", fontWeight: "400" }}
  //     >
  //       Publish
  //     </Menu.Item> */}
  //     <Menu.Item
  //       key="delete"
  //       className="pl-3 pr-5 primary_color"
  //       style={{ fontSize: "16px", fontWeight: "400" }}
  //       onClick={() => onDelete()}
  //     >
  //       Delete
  //     </Menu.Item>
  //   </Menu>
  // );

  // const onDelete = () => {
  //   dispatch(
  //     deleteBlogRequest(blogDetailData?.id)
  //   ).then((value) => {
  //     if (value.payload) {
  //       dispatch(getBlogListRequest({})).then((data) => {
  //         const blogID = data.payload?.data[0]?.id ?? null;
  //         dispatch(getBlogDetailRequest({ blogID }));
  //       });
  //     }

  //   });
  // }
  console.log('blogSideData', blogDetailData)

  return (
    <>
      {blogDetailStatus === STATUSES.LOADING ? (
        <Loading />
      ) : blogDetailStatus === STATUSES.ERROR ? (
        <div>{blogDetailError}</div>
      ) : (
        <BlogWrapped>
          <div className="row">
            <div className="col-sm-12">
              <div className="tab_section">
                {/* <div className="row">
                  <div className="col-sm-12 text-align-end">
                    <Dropdown overlay={menu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <i className="bi bi-three-dots-vertical primary_color"></i>
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                </div> */}
                <h1 className="blog_heading">
                  {blogDetailData?.title?.rendered ?? ""}
                </h1>
                {/* <p className="text_lightGray_color mb-4">
                  {blogDetailData?.category?.name ?? ""}
                </p> */}
                {/* <h3 className="secondary_color">Tags</h3> */}
                {/* <div className="card_right my-1">
                  {blogDetailData?.tags?.map((item, index) => (
                    <div className="tags_card" key={index}>
                      {item.tag?.name ?? ""}
                    </div>
                  ))}
                </div> */}
                {/* <div className="row">
                  <div className="col-lg-11 col-md-10">
                    <div className="banner_blog_style mt-5">
                      <img
                        src={blogDetailData?.banner_media ?? ""}
                        alt="Not Found!!"
                      />
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-2">
                    <div className="share">
                      <p>Share</p>
                      <ul
                        style={{ listStyle: "none", paddingLeft: "0px" }}
                        className="d-flex flex-column align-items-start"
                      >
                        <li>
                          <LinkedinShareButton url={blogDetailData?.permalink}>
                            <img src={Linkedin} alt="Not Found!!" />
                          </LinkedinShareButton>
                        </li>
                        <li>
                          <InstapaperShareButton url={blogDetailData?.permalink}>
                            <img src={Insta} alt="Not Found!!" />
                          </InstapaperShareButton >
                        </li>
                        <li>
                          <FacebookShareButton url={blogDetailData?.permalink}>
                            <img src={Facebook} alt="Not Found!!" />
                          </FacebookShareButton>
                        </li>
                        <li>
                          <TwitterShareButton url={blogDetailData?.permalink}>
                            <img src={Twitter} alt="Not Found!!" />
                          </TwitterShareButton>
                        </li>

                        <div className="copy_div" >
                          <Tooltip placement="topLeft" title={"Copied"} trigger="click">

                            <div className="like_div" onClick={() => navigator.clipboard.writeText(blogDetailData?.permalink)}>
                              <img src={Copy} alt="Not Found!!" />
                            </div>
                          </Tooltip>
                          <p className="text-center mt-2">Copy Link</p>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="mt-5 ">
                  {ReactHtmlParser(blogDetailData?.content.rendered ?? "")}
                </div>
              </div>
            </div>
          </div>
        </BlogWrapped>
      )}
    </>
  );
};

export default BlogDetails;
