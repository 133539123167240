import React from "react";
import { JobApprovedTileWrapped } from "./style";

const JobApprovedTile = ({ title, subTitle, isSeeker }) => {
  return (
    <>
      <JobApprovedTileWrapped>
        <div className="card_style">
          <div>
            <div className="">
              <p className="title_p mb-0">{title}</p>
              <span className="sub_title">{subTitle}</span>
            </div>
          </div>
        </div>
      </JobApprovedTileWrapped>
    </>
  );
};
export default JobApprovedTile;
