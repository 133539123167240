import React, { useState } from "react";
import { Typography, Avatar } from "antd";
import { Link } from "react-router-dom";
import AutoCompleteModal from "../modal/AutoCompleteModal";

const AutoCompleteSetup = ({ jobTitle, companyName, postTime, viewUrl }) => {
  const { Title } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <div className='style-card'>
        <div className='card-style-sub'>
          <Avatar.Group>
            <div className='avatar-info'>
              <Title level={5} className='table-heading'>
                {jobTitle}
              </Title>
              <p className='table-sub-heading'>{companyName}</p>
            </div>
          </Avatar.Group>{" "}
          <div className='ant-employed'>
            <p>
              <span className='time-style'>{postTime}</span>
            </p>
            {/* <span className="pl-6">
              <Link to="#" onClick={showModal}>
                <span className="seeker-color view-style"> View </span>
              </Link>
            </span> */}
            {/* <AutoCompleteModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AutoCompleteSetup;
