import styled from "styled-components";

const CounterCardStyle = styled.div`
  .cricleboxGreen {
    border: 0.5px solid rgba(51, 161, 97, 0.22);
    box-shadow: 0px 4px 15px rgba(118, 118, 118, 0.08);
    border-radius: 7px;
    .card_style {
      padding: 27px 25px;
      .title {
        font-weight: 700;
        font-size: 16px;
        color: #33a161;
        margin-bottom: 0px;
      }
      .sub_title {
        font-weight: 400;
        font-size: 11px;
        color: #646464;
      }
      .count {
        font-weight: 600;
        font-size: 40px;
        color: #33a161;
        margin-bottom: 0px;
        @media (max-width: 1278px) {
          font-size: 24px;
        }
      }
    }
  }
`;

const JobRequestListTileStyle = styled.div`
  width: 100%;
  background: rgba(252, 251, 251, 1);
  border: 1px solid rgba(168, 168, 168, 0.09);
  border-radius: 12px;

  ul li {
    border-bottom: 1px solid rgba(209, 209, 209, 0.69) !important;
    text-decoration: unset;
    padding: 30px 0px;
    .list-heading {
      font-weight: 500;
      font-size: 20px;
      color: #372354;
    }

    .sub-heading {
      font-weight: 400;
      font-size: 16px;
      color: #6f6f6f;
    }
    .sub2-heading {
      font-weight: 400;
      font-size: 14px;
      color: #92929d;
    }
  }
  .span-main-style {
    font-weight: 500;
    font-size: 14px;
    color: #44444f;
  }
  .job-category {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  .job-time {
    font-weight: 400;
    font-size: 14px;
    color: rgba(55, 35, 84, 0.44);
  }
`;

const ListSubMenuCardWrapped = styled.div``;

const JobApprovedTileWrapped = styled.div`
  border: 1px solid rgba(69, 90, 100, 0.2);
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  .card_style {
    padding: 16px 35px 16px 20px;
  }

  .title_p {
    font-weight: 400;
    font-size: 12px;
    color: #b5b5be;
  }
  .sub_title {
    font-weight: 500;
    font-size: 14px;
    color: #44444f;
  }
`;
const QrScanCardWrapped = styled.div`
  .qr_image_style {
    box-sizing: border-box;
    width: 185px;
    height: 186px;
    background: #f5f1f1;
    border: 0.5px solid #cccccc;
    border-radius: 5px;
    img {
      padding: 6px;
    }
  }
`;

export {
  CounterCardStyle,
  JobRequestListTileStyle,
  ListSubMenuCardWrapped,
  JobApprovedTileWrapped,
  QrScanCardWrapped,
};
