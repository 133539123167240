import React from "react";
import Upload from "rc-upload";

const BannerImageCard = ({
  title,
  imageUrl,
  imageText,
  setImageUrl,
  setImageFile,
  isBlogImage,
}) => {
  const handleBeforeUpload = (file) => {
    setImageFile(file);
    setImageUrl(URL.createObjectURL(file));
  };
  return (
    <>
      <div className="row my-4">
        <div className="banner_text_style mb-1">
          <h4>{title}</h4>
        </div>
        <div className="banner_section">
          <div className={isBlogImage ? "blog_image_style" : "banner_image_style"}>
            <img src={imageUrl} alt="Not Found!!" />
          </div>
          <Upload
            accept=".png, .jpg, .jpeg, .svg, .webp"
            beforeUpload={handleBeforeUpload}
          >
            <div className="upload_image_style">{imageText}</div>
          </Upload>
        </div>
      </div>
    </>
  );
};
export default BannerImageCard;
