import React from "react";
import { ContentManagementWrapped } from "../style";
import SideBarTab from "../../components/tabs/SideBarTab";
import HomeContentPage from "./HomeContentPage";
import AboutUs from "./about-us";
import PrivacyPolicy from "./privacy-policy";
import PicturePolicy from "./picture-policy";
import TermsOfService from "./TermsOfService";
import ContactPage from "./ContactPage";

const ContentManagement = () => {
  return (
    <>
      <ContentManagementWrapped>
        <div className="layout-content">
          <div className="row">
            <div className="col-sm-3">
              <SideBarTab />
            </div>
            <div className="col-sm-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  aria-labelledby="v-pills-home-tab"
                  role="tabpanel"
                >
                  <HomeContentPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <ContactPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  <AboutUs />
                </div>

                <div
                  className="tab-pane fade"
                  id="privacy"
                  role="tabpanel"
                  aria-labelledby="privacy-tab"
                >
                  <PrivacyPolicy />
                </div>
                <div
                  className="tab-pane fade"
                  id="picture"
                  role="tabpanel"
                  aria-labelledby="picture-tab"
                >
                  <PicturePolicy />
                </div>
                <div
                  className="tab-pane fade"
                  id="terms"
                  role="tabpanel"
                  aria-labelledby="terms-tab"
                >
                  <TermsOfService />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentManagementWrapped>
    </>
  );
};

export default ContentManagement;
