import React from "react";
import { InputNumber, Form } from "antd";
import styled from "styled-components";

const NumberInputWrapped = styled.div`
  label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .w-100 {
    margin: 0px;
    padding-bottom: 30px;
  }
  .style_input {
    color: rgba(100, 100, 100, 1);
    padding: 6.5px 11px;
    font-size: 16px;
    .ant-input-number-input {
      height: 40px;
      color: rgba(100, 100, 100, 1);
      font-weight: 400;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        height: 40px;
      }
    }
  }
`;
const NumberInput = ({
  placeText,
  inputIcon,
  labelText,
  name,
  sizes,
  addon,
  req,
  maxLength,
  marginBottom,
}) => {
  return (
    <NumberInputWrapped>
      <Form.Item
        name={name || "name"}
        label={labelText || ""}
        className={marginBottom ? `${marginBottom} w-100` : "w-100 mb-4"}
        rules={[
          {
            required: req === "false" ? false : true,
            message: "This field is required!!",
          },
        ]}
      >
        <InputNumber
          style={{ width: "100%" }}
          addonAfter={addon || ""}
          prefix={inputIcon || ""}
          placeholder={placeText || "Type Here"}
          size={sizes || "large"}
          maxLength={maxLength || 250}
          className="style_input"
        />
      </Form.Item>
    </NumberInputWrapped>
  );
};

export default NumberInput;
