import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import TextEditor from "../../components/TextEditor/TextEditor";
import BannerImage from "../../assets/images/bannerImage.png";
import { ContentWrapped } from "./style";
import BannerImageCard from "../../components/shared/bannerImageCard";
import SaveButton from "../../components/button/saveButton";
import HeadingTitle from "../../components/shared/headingTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactPageContentRequest,
  postContactPageContentRequest,
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { STATUSES } from "../../config/constants/defaultValues";

const ContactPage = () => {
  const dispatch = useDispatch();
  const { contactPageData, contactPageStatus, contactPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [imageFile, setImageFile] = useState();
  const [editorData, setEditorData] = useState();
  const [editorEditData, setEditorEditData] = useState();
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);
  const [faqEditorData, setFaqEditorData] = useState();
  const [faqEditorEditData, setFaqEditorEditData] = useState();
  const [passwordRetrievalEditorData, setPasswordRetrievalEditorData] =
    useState();
  const [passwordRetrievalEditorEditData, setPasswordRetrievalEditorEditData] =
    useState();
  const [submitResumeEditorData, setSubmitResumeEditorData] = useState();
  const [submitResumeEditorEditData, setSubmitResumeEditorEditData] =
    useState();
  const [applyForJobEditorData, setApplyForJobEditorData] = useState();
  const [applyForJobEditorEditData, setApplyForJobEditorEditData] = useState();
  const [emailEditorData, setEmailEditorData] = useState();
  const [emailEditorEditData, setEmailEditorEditData] = useState();
  const [privacyPolicyEditorData, setPrivacyPolicyEditorData] = useState();
  const [privacyPolicyEditorEditData, setPrivacyPolicyEditorEditData] =
    useState();
  const [picturePolicyEditorData, setPicturePolicyEditorData] = useState();
  const [picturePolicyEditorEditData, setPicturePolicyEditorEditData] =
    useState();
  const [termsOfServicesEditorData, setTermsOfServicesEditorData] = useState();
  const [termsOfServicesEditorEditData, setTermsOfServicesEditorEditData] =
    useState();

  useEffect(() => {
    dispatch(getContactPageContentRequest());
  }, [dispatch]);
  useEffect(() => {
    if (contactPageData) {
      form.setFieldsValue({
        heading: contactPageData?.heading,
        bread_crumbs: contactPageData?.bread_crumbs,
        description: contactPageData?.description,
        email: contactPageData?.email,
        email_pre_text: contactPageData?.email_pre_text,
        faq_heading: contactPageData?.faq_heading,
        password_retrieval_heading: contactPageData?.password_retrieval_heading,
        submit_resume_heading: contactPageData?.submit_resume_heading,
        apply_for_job_heading: contactPageData?.apply_for_job_heading,
        email_heading: contactPageData?.email_heading,
        privacy_policy_heading: contactPageData?.privacy_policy_heading,
        picture_policy_heading: contactPageData?.picture_policy_heading,
        terms_of_services_heading: contactPageData?.terms_of_services_heading,
      });
      setImageUrl(contactPageData?.banner_media ?? BannerImage)
      setEditorEditData(contactPageData?.description);
      setFaqEditorEditData(contactPageData?.faq_text);
      setPasswordRetrievalEditorEditData(
        contactPageData?.password_retrieval_text
      );
      setSubmitResumeEditorEditData(contactPageData?.submit_resume_text);
      setApplyForJobEditorEditData(contactPageData?.apply_for_job_text);
      setEmailEditorEditData(contactPageData?.email_text);
      setPrivacyPolicyEditorEditData(contactPageData?.privacy_policy_text);
      setPicturePolicyEditorEditData(contactPageData?.picture_policy_text);
      setTermsOfServicesEditorEditData(contactPageData?.terms_of_services_text);
    }
  }, [contactPageData, form]);
  const onSave = (values) => {
    dispatch(
      postContactPageContentRequest({
        ...values,
        banner_media: imageFile ?? "",
        description: editorData,
        id: contactPageData?.id,
        title: contactPageData?.title,
        faq_text: faqEditorData,
        password_retrieval_text: passwordRetrievalEditorData,
        submit_resume_text: submitResumeEditorData,
        apply_for_job_text: applyForJobEditorData,
        email_text: emailEditorData,
        privacy_policy_text: privacyPolicyEditorData,
        picture_policy_text: picturePolicyEditorData,
        terms_of_services_text: termsOfServicesEditorData,
        _method: 'PUT'
      })
    );
  };
  return (
    <>
      {contactPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : contactPageError === STATUSES.ERROR ? (
        <div>{contactPageError}</div>
      ) : (
        <ContentWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onSave}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Contact Page</h3>
                    <SaveButton title="Save" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Page Heading"
                      name="heading"
                      placeText="About Jump Recruiter"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Bread Crumbs"
                      name="bread_crumbs"
                      placeText="Bread Crumbs"
                    />

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Email Pre Text"
                      name="email_pre_text"
                      placeText="Email Pre Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Email"
                      name="email"
                      inputType="email"
                      placeText="Email"
                    />
                    <BannerImageCard
                      title="Banner Image"
                      imageUrl={imageUrl}
                      setImageFile={setImageFile}
                      setImageUrl={setImageUrl}
                      imageText="Change Image"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <HeadingTitle title="Description Section" />
                    <TextEditor
                      labelText="Description"
                      setEditorData={setEditorData}
                      editorEditData={editorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}

                    />
                  </div>
                </div>
                {/* FAQ Heading */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQ Heading"
                      name="faq_heading"
                      placeText="FAQ Heading"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="FAQ Text"
                      setEditorData={setFaqEditorData}
                      editorEditData={faqEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}


                    />
                  </div>
                </div>
                {/* Password Retrieval */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Password Retrieval Heading"
                      name="password_retrieval_heading"
                      placeText="Password Retrieval Heading"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Password Retrieval Text"
                      setEditorData={setPasswordRetrievalEditorData}
                      editorEditData={passwordRetrievalEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}



                    />
                  </div>
                </div>
                {/* Submit Resume */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Submit Resume Heading"
                      name="submit_resume_heading"
                      placeText="Submit Resume Heading"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Submit Resume Text"
                      setEditorData={setSubmitResumeEditorData}
                      editorEditData={submitResumeEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}



                    />
                  </div>
                </div>
                {/* Apply for Job Heading */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Apply for Job Heading "
                      name="apply_for_job_heading"
                      placeText="Apply for Job Heading "
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Apply for Job Text  "
                      setEditorData={setApplyForJobEditorData}
                      editorEditData={applyForJobEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}



                    />
                  </div>
                </div>
                {/* Email Heading */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Email Heading "
                      name="email_heading"
                      placeText="Email Heading "
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Email Text"
                      setEditorData={setEmailEditorData}
                      editorEditData={emailEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}



                    />
                  </div>
                </div>
                {/* Privacy Policy Heading */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Privacy Policy Heading "
                      name="privacy_policy_heading"
                      placeText="Privacy Policy Heading  "
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Privacy Policy Text"
                      setEditorData={setPrivacyPolicyEditorData}
                      editorEditData={privacyPolicyEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}



                    />
                  </div>
                </div>
                {/* Picture Policy Heading */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Picture Policy Heading "
                      name="picture_policy_heading"
                      placeText="Picture Policy Heading  "
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Picture Policy Text"
                      setEditorData={setPicturePolicyEditorData}
                      editorEditData={picturePolicyEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}



                    />
                  </div>
                </div>
                {/* Terms of Services Heading */}
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Terms of Services Heading "
                      name="terms_of_services_heading"
                      placeText="Terms of Services Heading "
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Terms of Services Text"
                      setEditorData={setTermsOfServicesEditorData}
                      editorEditData={termsOfServicesEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}



                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </ContentWrapped>
      )}
    </>
  );
};

export default ContactPage;
