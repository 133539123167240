import React, { useState, useEffect } from "react";
import { AutoComplete, Form, Spin } from "antd";
import { useDebounce } from "use-debounce";

const { Option } = AutoComplete;

const AutoCompletes = ({
  placeText,
  labelText,
  name,
  sizes,
  options,
  loading,
  req,
  marginBottom,
  setAutoCompleteValue,
}) => {
  const [autocompleteSearchValue, setAutocompleteSearchValue] = useState("");
  const [selectItemId, setSelectItemId] = useState(false);

  const [value] = useDebounce(autocompleteSearchValue, 1000);

  const handleAutocompleteSearch = (searchText) => {
    setAutocompleteSearchValue(searchText);
  };

  useEffect(() => {
    if (value) {
      setAutoCompleteValue(value);
    }
  }, [value]);

  const HandleOnSelect = (_, val) => {
    setSelectItemId(true);
  };

  return (
    <>
      <Form.Item
        name={name || "name"}
        label={labelText || ""}
        className={marginBottom ? `${marginBottom} w-100` : "w-100 mb-4"}
        rules={[
          {
            required: req === false ? false : true,
            message: "This field is required!!",
          },
          {
            message: "Please select a value",
            validator: (_, value) => {
              if (selectItemId) {
                return Promise.resolve();
              } else {
                return Promise.reject("Some message here");
              }
            },
          },
        ]}
      >
        <AutoComplete
          showArrow
          showSearch
          placeholder={placeText || "Select Here"}
          size={sizes || "middle"}
          onSearch={handleAutocompleteSearch}
          onSelect={HandleOnSelect}
          notFoundContent={loading ? <Spin /> : ""}
        >
          {options?.map((item) => {
            return (
              <Option key={item?.id} value={[item?.title.rendered, item.id]}>
                {item?.title.rendered}
              </Option>
            );
          })}
        </AutoComplete>
      </Form.Item>
    </>
  );
};

export default AutoCompletes;
