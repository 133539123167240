import React, { useState } from "react";
import { Form, Menu } from "antd";
import { SystemConfigurationSideBarWrapped } from "../system/style";
import BaseInput from "../../components/form/BaseInput";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getBlogManagementDetailRequest } from "../../redux/blogSlice";


const BlogConfigurationSideBar = ({ blogManagementDataList }) => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState(blogManagementDataList);
  const getBlogConfigurationDetail = (systemConfiguration) => {
    dispatch(getBlogManagementDetailRequest({ blog: systemConfiguration }));
  };

  const updateInput = (inputData) => {
    const filtered = blogManagementDataList.filter((blogData) => {
      if (inputData.target.value === '') {
        return blogManagementDataList;
      }
      else {
        return blogData.title.toLowerCase().includes(inputData.target.value.toLowerCase())
      }
    })
    setDataList(filtered);
  }
  return (
    <>
      <SystemConfigurationSideBarWrapped>
        <div className="side-tabs">
          <div className="m-0">
            <Form>
              <BaseInput
                className="search_style"
                name="generalSearch"
                placeText="Search"
                inputIcon={<SearchOutlined />}
                onChange={updateInput}
                req={false}
              />
            </Form>
          </div>
          <div>
            <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]}>
              {dataList.map((blogManagementData, index) => (
                <Menu.Item key={index}
                  onClick={() => getBlogConfigurationDetail(blogManagementData)}
                >
                  <span className="label">{blogManagementData.title}</span>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </div>
      </SystemConfigurationSideBarWrapped>
    </>
  );
};
export default BlogConfigurationSideBar;
