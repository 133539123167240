import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES, USER_TYPE } from "../config/constants/defaultValues";
import { LOGIN_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const loginRequest = createAsyncThunk(
    "/login",
    async ({ values }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                LOGIN_API,
                REQUEST_METHOD.POST,
                values,
                false
            );
            if (response.status === 200) {
                if (
                    response.data.data.roles[0].id === 3 ||
                    response.data.data.roles[0].id === 4 ||
                    response.data.data.roles[0].id === 5 ||
                    response.data.data.roles[0].id === 6
                ) {
                    successNotification(response.data.message);
                    return response.data.data;
                }
                else {
                    return errorNotification('Enter your admin username and password here.');
                }
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState: {
        userData: JSON.parse(localStorage.getItem("user")),
        status: STATUSES.IDLE,
        userType: JSON.parse(localStorage.getItem("user"))?.type,
        error: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.userData = action.payload;
        },
        setLogout: (state, action) => {
            localStorage.clear();
            state.userData = null;
        },
    },
    extraReducers: {
        [loginRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [loginRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            localStorage.setItem('user', JSON.stringify(action.payload));
            state.userType = action.payload?.type;
            state.userData = action.payload;
        },
        [loginRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
    },
});
export default authSlice.reducer;
export const { setUser, setLogout } = authSlice.actions;

