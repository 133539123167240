import { Form } from "antd";
import React, { useEffect, useState } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import InputNumber from "../../components/form/NumberInput";
import BannerImageCard from "../../components/shared/bannerImageCard";
import BannerImage from "../../assets/images/bannerImage.png";
import QrImage from "../../assets/images/qr.png";
import TextEditor from "../../components/TextEditor/TextEditor";
import { HomeWrapped } from "./style";
import HeadingTitle from "../../components/shared/headingTitle";
import QrScanCard from "../../components/shared/qrScanCard ";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeContentPageContentRequest,
  postHomeContentPageContentRequest,
} from "../../redux/contentManagementSlice";
import { STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";

const HomeContentPage = () => {
  const dispatch = useDispatch();
  const { homeContentPageData, homeContentPageStatus, homeContentPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();
  // TextEditor
  const [bannerEditorData, setBannerEditorData] = useState();
  const [bannerEditorEditData, setBannerEditorEditData] = useState();
  const [aboutParagraphEditorData, setAboutParagraphEditorData] = useState();
  const [aboutParagraphEditorEditData, setAboutParagraphEditorEditData] =
    useState();
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);


  // images
  const [navbarLogoUrl, setNavbarLogo] = useState();
  const [navbarLogoFile, setNavbarLogoFile] = useState();
  const [bannerMediaUrl, setBannerMedia] = useState();
  const [bannerMediaFile, setBannerMediaFile] = useState();
  const [mobileAppMockupUrl, setMobileAppMockupUrl] = useState();
  const [mobileAppMockupFile, setMobileAppMockupFile] = useState();
  const [playstoreImageUrl, setPlaystoreImageUrl] = useState();
  const [playstoreImageFile, setPlaystoreImageFile] = useState();
  const [appstoreImageUrl, setAppstoreImageUrl] = useState();
  const [appstoreImageFile, setAppstoreImageFile] = useState();
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState();
  const [qrCodeImageFile, setQrCodeImageFile] = useState();
  const [footerLogoImageUrl, setFooterLogoImageUrl] = useState();
  const [footerLogoImageFile, setFooterLogoImageFile] = useState();
  const [commonBannerMediaImageUrl, setCommonBannerMediaImageUrl] = useState();
  const [commonBannerMediaImageFile, setCommonBannerMediaImageFile] =
    useState();

  useEffect(() => {
    dispatch(getHomeContentPageContentRequest());
  }, [dispatch]);
  useEffect(() => {
    if (homeContentPageData) {
      form.setFieldsValue({
        welcome_note: homeContentPageData?.welcome_note,
        navbar_profile_menu_text: homeContentPageData?.navbar_profile_menu_text,
        navbar_employers_menu_text:
          homeContentPageData?.navbar_employers_menu_text,
        navbar_jobs_menu_text: homeContentPageData?.navbar_jobs_menu_text,
        navbar_job_alerts_menu_text:
          homeContentPageData?.navbar_job_alerts_menu_text,
        navbar_following_menu_text:
          homeContentPageData?.navbar_following_menu_text,
        navbar_account_setting_menu_text:
          homeContentPageData?.navbar_account_setting_menu_text,
        navbar_help_menu_text: homeContentPageData?.navbar_help_menu_text,
        navbar_log_out_menu_text: homeContentPageData?.navbar_log_out_menu_text,
        home_menu_text: homeContentPageData?.home_menu_text,
        companies_menu_text: homeContentPageData?.companies_menu_text,
        about_us_menu_text: homeContentPageData?.about_us_menu_text,
        jobs_menu_text: homeContentPageData?.jobs_menu_text,
        contact_us_menu_text: homeContentPageData?.contact_us_menu_text,
        post_job_btn_text: homeContentPageData?.post_job_btn_text,
        sign_in_btn_text: homeContentPageData?.sign_in_btn_text,
        job_title_input_placeholder:
          homeContentPageData?.job_title_input_placeholder,
        city_placeholder: homeContentPageData?.city_placeholder,
        salary_range_text: homeContentPageData?.salary_range_text,
        salary_range_start: homeContentPageData?.salary_range_start,
        salary_range_end: homeContentPageData?.salary_range_end,
        find_job_btn_text: homeContentPageData?.find_job_btn_text,
        search_by_industry: homeContentPageData?.search_by_industry,
        featured_jobs: homeContentPageData?.featured_jobs,
        hiring_companies: homeContentPageData?.hiring_companies,
        mockup_text: homeContentPageData?.mockup_text,
        play_store_app_link: homeContentPageData?.play_store_app_link,
        app_store_app_link: homeContentPageData?.app_store_app_link,
        scan_text: homeContentPageData?.scan_text,
        jobs_by_city_text: homeContentPageData?.jobs_by_city_text,
        jobs_by_functional_area_text:
          homeContentPageData?.jobs_by_functional_area_text,
        jobs_by_industry_text: homeContentPageData?.jobs_by_industry_text,
        jobs_by_company_text: homeContentPageData?.jobs_by_company_text,
        get_in_touch_text: homeContentPageData?.get_in_touch_text,
        download_app_text: homeContentPageData?.download_app_text,
        copy_right_text: homeContentPageData?.copy_right_text,
        twitter_link: homeContentPageData?.twitter_link,
        facebook_link: homeContentPageData?.facebook_link,
        instagram_link: homeContentPageData?.instagram_link,
        linkedin_link: homeContentPageData?.linkedin_link,
        currency_symbol: homeContentPageData?.currency_symbol,
        currency_code: homeContentPageData?.currency_code,
        currency_name: homeContentPageData?.currency_name,
      });
      setNavbarLogo(homeContentPageData?.navbar_logo ?? BannerImage);
      setBannerMedia(homeContentPageData?.banner_media ?? BannerImage);
      setMobileAppMockupUrl(
        homeContentPageData?.mobile_app_mockup ?? BannerImage
      );
      setPlaystoreImageUrl(
        homeContentPageData?.play_store_image ?? BannerImage
      );
      setAppstoreImageUrl(homeContentPageData?.app_store_image ?? BannerImage);
      setQrCodeImageUrl(homeContentPageData?.qr_code ?? QrImage);
      setFooterLogoImageUrl(homeContentPageData?.footer_logo ?? BannerImage);
      setCommonBannerMediaImageUrl(
        homeContentPageData?.common_banner_media ?? BannerImage
      );
      setBannerEditorEditData(homeContentPageData?.banner_text);
      setAboutParagraphEditorEditData(homeContentPageData?.about_paragraph);
    }
  }, [homeContentPageData, form]);
  const onSave = (values) => {
    dispatch(
      postHomeContentPageContentRequest({
        ...values,
        id: homeContentPageData?.id,
        title: homeContentPageData?.title,

        // images File
        navbar_logo: navbarLogoFile ?? "",
        banner_media: bannerMediaFile ?? "",
        mobile_app_mockup: mobileAppMockupFile ?? "",
        play_store_image: playstoreImageFile ?? "",
        app_store_image: appstoreImageFile ?? "",
        qr_code: qrCodeImageFile ?? "",
        footer_logo: footerLogoImageFile ?? "",
        common_banner_media: commonBannerMediaImageFile ?? "",
        // TextEditor
        banner_text: bannerEditorData,
        about_paragraph: aboutParagraphEditorData,
        _method: 'PUT'
      })
    );
  };
  return (
    <>
      {homeContentPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : homeContentPageStatus === STATUSES.ERROR ? (
        <div>{homeContentPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onSave}
                validateTrigger
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Home</h3>
                    <SaveButton title="Save" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* NavBar Section */}
                    {/* <HeadingTitle title="NavBar Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Welcome Note"
                      name="welcome_note"
                      placeText="Welcome Note"
                    />
                    <BannerImageCard
                      title="Navbar Logo"
                      imageUrl={navbarLogoUrl}
                      setImageFile={setNavbarLogoFile}
                      setImageUrl={setNavbarLogo}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Navbar Profile Menu Text"
                      name="navbar_profile_menu_text"
                      placeText="Navbar Profile Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Navbar Employers Menu Text"
                      name="navbar_employers_menu_text"
                      placeText="Navbar Employers Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Navbar Jobs Menu Text"
                      name="navbar_jobs_menu_text"
                      placeText="Navbar Jobs Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Navbar Job Alerts Menu Text"
                      name="navbar_job_alerts_menu_text"
                      placeText="Navbar Job Alerts Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Navbar Account Setting Menu Text"
                      name="navbar_account_setting_menu_text"
                      placeText="Navbar Account Setting Menu Text"
                    />

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Navbar Log Out Menu Text"
                      name="navbar_log_out_menu_text"
                      placeText="Navbar Log Out Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Home Menu Text"
                      name="home_menu_text"
                      placeText="Home Menu Text"
                    />

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Companies Menu Text"
                      name="companies_menu_text"
                      placeText="Companies Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Us Menu Text"
                      name="about_us_menu_text"
                      placeText="About Us Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Jobs Menu Text"
                      name="jobs_menu_text"
                      placeText="Jobs Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Contact Us Menu Text"
                      name="contact_us_menu_text"
                      placeText="Contact Us Menu Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Post Job Button Text"
                      name="post_job_btn_text"
                      placeText="Post Job"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Sign In Button Text"
                      name="sign_in_btn_text"
                      placeText="Sign In"
                    />
                  </div>
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Main Banner Section */}
                    <HeadingTitle title="Main Banner Section" />
                    <BannerImageCard
                      title="Banner Image"
                      imageUrl={bannerMediaUrl}
                      setImageFile={setBannerMediaFile}
                      setImageUrl={setBannerMedia}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Job Title Input Placeholder"
                      name="job_title_input_placeholder"
                      placeText="Job Title or Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="City Placeholder"
                      name="city_placeholder"
                      placeText="City"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Salary Range Text"
                      name="salary_range_text"
                      placeText="Salary Range Text"
                    />
                    <InputNumber
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Salary Range Start"
                      name="salary_range_start"
                      placeText="Salary Range Start"
                    />
                    <InputNumber
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Salary Range End"
                      name="salary_range_end"
                      placeText="Salary Range End"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Find Job Button Text"
                      name="find_job_btn_text"
                      placeText="Salary Range Text"
                    />
                  </div>
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Banner Text"
                      setEditorData={setBannerEditorData}
                      editorEditData={bannerEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}

                    />
                  </div>
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <HeadingTitle title="Search by Industry Section" />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Search by Industry"
                      name="search_by_industry"
                      placeText="Search by Industry"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Featured Jobs"
                      name="featured_jobs"
                      placeText="Featured Jobs"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Hiring Companies"
                      name="hiring_companies"
                      placeText="Hiring Companies"
                    />

                    <HeadingTitle title="Mobile App Banner Section" />
                    <BannerImageCard
                      title="Mobile App Mockup"
                      imageUrl={mobileAppMockupUrl}
                      setImageFile={setMobileAppMockupFile}
                      setImageUrl={setMobileAppMockupUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Mockup Text"
                      name="mockup_text"
                      placeText="Mockup Text"
                    />
                    <BannerImageCard
                      title="Play Store Image"
                      imageUrl={playstoreImageUrl}
                      setImageFile={setPlaystoreImageFile}
                      setImageUrl={setPlaystoreImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Play Store App Link"
                      name="play_store_app_link"
                      placeText="Play Store App Link"
                    />
                    <BannerImageCard
                      title="App Store Image"
                      imageUrl={appstoreImageUrl}
                      setImageFile={setAppstoreImageFile}
                      setImageUrl={setAppstoreImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="App Store App Link"
                      name="app_store_app_link"
                      placeText="App Store App Link"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Scan Text"
                      name="scan_text"
                      placeText="Scan Text"
                    />
                    <QrScanCard
                      title="Qr Code"
                      imageUrl={qrCodeImageUrl}
                      setImageFile={setQrCodeImageFile}
                      setImageUrl={setQrCodeImageUrl}
                      imageText="Change Image"
                    />
                  </div>
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <HeadingTitle title="Footer Section" />
                    <BannerImageCard
                      title="Footer Logo"
                      imageUrl={footerLogoImageUrl}
                      setImageFile={setFooterLogoImageFile}
                      setImageUrl={setFooterLogoImageUrl}
                      imageText="Change Image"
                    />

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Jobs by City Text"
                      name="jobs_by_city_text"
                      placeText="Jobs by City Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Jobs by Functional Area Text"
                      name="jobs_by_functional_area_text"
                      placeText="Jobs by Functional Area Text"
                    />

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Jobs by Industry Text"
                      name="jobs_by_industry_text"
                      placeText="Jobs by Industry Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Jobs by Company Text"
                      name="jobs_by_company_text"
                      placeText="Jobs by Company Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Jobs by Company Text"
                      name="jobs_by_company_text"
                      placeText="Jobs by Company Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get In Touch Text"
                      name="get_in_touch_text"
                      placeText="Get In Touch Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Download App Text"
                      name="download_app_text"
                      placeText="Download App Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Copy Right Text"
                      name="copy_right_text"
                      placeText="Copy Right Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Twitter Link"
                      name="twitter_link"
                      placeText="Twitter Link"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Facebook Link"
                      name="facebook_link"
                      placeText="Facebook Link"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Instagram Link"
                      name="instagram_link"
                      placeText="Instagram Link"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Linkedin Link"
                      name="linkedin_link"
                      placeText="Linkedin Link"
                    />
                    <BannerImageCard
                      title="Common Banner Media"
                      imageUrl={commonBannerMediaImageUrl}
                      setImageFile={setCommonBannerMediaImageFile}
                      setImageUrl={setCommonBannerMediaImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Currency Symbol"
                      name="currency_symbol"
                      placeText="Currency Symbol"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Currency Code"
                      name="currency_code"
                      placeText="Currency Code"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Currency Name"
                      name="currency_name"
                      placeText="Currency Name"
                    />
                  </div>
                  <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="About Paragraph"
                      setEditorData={setAboutParagraphEditorData}
                      editorEditData={aboutParagraphEditorEditData}
                      setIsEditorEmpty={setIsEditorEmpty}

                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default HomeContentPage;
