import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { GET_SYSTEM_CONFIGURATION_DETAIL_API, GET_SYSTEM_CONFIGURATION_LIST_API, POST_SYSTEM_CONFIGURATION_DETAIL_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getSystemConfigurationListRequest = createAsyncThunk(
    "systemConfiguration/getSystemConfigurationListRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_LIST_API,
                REQUEST_METHOD.GET,
                {},
                true
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getSystemConfigurationDetailRequest = createAsyncThunk(
    "systemConfiguration/getSystemConfigurationDetailRequest",
    async ({ modelData }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                {
                    model_path: modelData.model_path,
                    model_name: modelData.model_name,
                    status: 1,
                },
                true
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const systemConfigurationPostRequest = createAsyncThunk(
    "systemConfiguration/systemConfigurationPostRequest",
    async (values, { rejectWithValue }) => {
        try {
            const response = await apiService(
                POST_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                values,
                true
            );

            if (response.status === 200) {
                successNotification(response.data.message);
                return true;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return false;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return false;
        }
    }
);


const SystemConfigurationSlice = createSlice({
    name: "systemConfiguration",
    initialState: {
        systemConfigurationListData: null,
        systemConfigurationListStatus: STATUSES.IDLE,
        systemConfigurationListError: null,
        // systemConfiguration Detail
        systemConfigurationDetailData: null,
        systemConfigurationDetailStatus: STATUSES.IDLE,
        systemConfigurationDetailError: null,
    },
    extraReducers: {
        [getSystemConfigurationListRequest.pending]: (state) => {
            state.systemConfigurationListStatus = STATUSES.LOADING;
        },
        [getSystemConfigurationListRequest.fulfilled]: (state, action) => {

            state.systemConfigurationListStatus = STATUSES.IDLE;
            state.systemConfigurationListData = action.payload;
        },
        [getSystemConfigurationListRequest.rejected]: (state, action) => {
            state.systemConfigurationListStatus = STATUSES.ERROR;
            state.systemConfigurationListError = action.payload;
        },
        [getSystemConfigurationDetailRequest.pending]: (state) => {
            state.systemConfigurationDetailStatus = STATUSES.LOADING;
        },
        [getSystemConfigurationDetailRequest.fulfilled]: (state, action) => {

            state.systemConfigurationDetailStatus = STATUSES.IDLE;
            state.systemConfigurationDetailData = action.payload;
        },
        [getSystemConfigurationDetailRequest.rejected]: (state, action) => {
            state.systemConfigurationDetailStatus = STATUSES.ERROR;
            state.systemConfigurationDetailError = action.payload;
        },
    },
});
export default SystemConfigurationSlice.reducer;

