import React from "react";
import { Typography, Avatar } from "antd";
import { Link } from "react-router-dom";

const JobApproval = ({ imageUrl, jobTitle, companyName, postTime, id }) => {
  const { Title } = Typography;

  return (
    <>
      <div className='style-card'>
        <div className='card-style-sub'>
          <Avatar.Group>
            <Avatar
              className='shape-avatar'
              shape='circle'
              size={63}
              src={imageUrl}
            ></Avatar>
            <div className='avatar-info'>
              <Title level={5} className='table-heading'>
                {jobTitle}
              </Title>
              <p className='table-sub-heading'>{companyName}</p>
              <p>
                <span className='time-style'>{postTime}</span>
              </p>
            </div>
          </Avatar.Group>{" "}
          <div className='ant-employed'>
            <span className='pr-5'>
              <Link to={`/jobRequest/JobDetails/${id}`}>
                <span className='seeker-color view-style'> View </span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default JobApproval;
