import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dashboardReducer from "./dashboardSlice";
import jobApprovalRequestReducer from "./jobApprovalRequestSlice";
import contentManagementReducer from "./contentManagementSlice";
import blogReducer from "./blogSlice";
import autoCompleteApprovalReducer from "./autoCompleteApprovalRequestSlice";
import systemConfigurationReducer from "./systemConfigurationSlice";
export default configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    jobApprovalRequest: jobApprovalRequestReducer,
    contentManagement: contentManagementReducer,
    blog: blogReducer,
    autoCompleteApproval: autoCompleteApprovalReducer,
    systemConfiguration: systemConfigurationReducer,
  },
});
