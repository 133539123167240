import React, { useEffect, useState } from "react";
import { BlogWrapped } from "./style";
import BannerImage from "../../assets/images/bannerImage.png";
import SaveButton from "../../components/button/saveButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RejectButton from "../../components/button/rejectButton";
import BaseInput from "../../components/form/BaseInput";
import BannerImageCard from "../../components/shared/bannerImageCard";
import { Button, Form } from "antd";
import TextEditor from "../../components/TextEditor/TextEditor";
import Selects from "../../components/select/Selects";
import TagSelect from "../../components/select/TagSelect";
import { useDispatch, useSelector } from "react-redux";
import { blogPostRequest, getCategoryListRequest, updateBlogPostRequest } from "../../redux/blogSlice";

const BlogPost = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [category, setCategory] = useState();
  const [imageFile, setImageFile] = useState();
  const [isPending, setIsPending] = useState(false);
  // const [isPreview, setIsPreview] = useState(false);
  const [editorData, setEditorData] = useState();
  const [editorEditData, setEditorEditData] = useState();
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);
  const [categoryName, setCategoryName] = useState();
  const location = useLocation();
  const { blogDetailData } = useSelector((state) => state.blog);
  useEffect(() => {
    if (location.state && blogDetailData) {
      const tagIds = []
      for (const singleTag of blogDetailData.tags) {
        tagIds.push(singleTag.tag?.name ?? '');
      }
      form.setFieldsValue({
        heading: blogDetailData.heading,
        description: blogDetailData.description,
        permalink: blogDetailData.permalink,
        search_description: blogDetailData.permalink,
        tag_ids: tagIds,
        category_id: blogDetailData.category_id,
      });
      setImageUrl(blogDetailData.banner_media ?? BannerImage)
      setEditorEditData(blogDetailData.description)
    }
  }, [location.state, blogDetailData, form])

  useEffect(() => {
    dispatch(getCategoryListRequest()).then((categoryList) => {
      setCategory(categoryList.payload);
    });
  }, [dispatch]);

  const onSave = (values) => {
    // if (isPreview) {
    //   navigate("/PreviewBlog", {
    //     state: {
    //       ...values,
    //       banner_media: imageUrl ?? null,
    //       status: isPending ? 0 : 1,
    //       description: editorData,
    //       categoryName: categoryName
    //     }
    //   })
    // } else {
    dispatch(
      location.state ? updateBlogPostRequest({
        ...values,
        banner_media: imageFile ?? null,
        status: isPending ? 0 : 1,
        description: editorData,
        _method: "PUT",
        id: blogDetailData?.id,
      }) : blogPostRequest(
        {
          ...values,
          banner_media: imageFile ?? null,
          status: isPending ? 0 : 1,
          description: editorData,
        }

      )
    ).then((value) => {
      if (value.payload) {
        navigate(-1);
      }
    });
    // }

  };
  return (
    <>
      <BlogWrapped>
        <Form layout="vertical" className="pt-3" form={form} onFinish={onSave}>
          <div className="layout-content">
            <div className="row">
              <div className="col-sm-12 content_between pb-3">
                <div className="d-flex align-items-center">
                  <h1 className="heading" style={{ paddingLeft: "10px" }}>
                    Post a Blog
                  </h1>
                  {/* <Button
                    className="text_underline"
                    style={{ paddingLeft: "20px", cursor: "pointer" }}
                    onClick={() => setIsPreview(true)}
                    htmlType='submit'
                    type="link"
                  >
                    Preview
                  </Button> */}
                </div>
                <div className="d-flex">
                  {!location.state ? <div></div> : <RejectButton
                    title="Save"
                    onClick={() => setIsPending(true)}
                  />}
                  <SaveButton title="Publish" />
                </div>
              </div>
            </div>
            <div className="row px-3">
              <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12">
                <BaseInput
                  inputIcon={<i className="bi bi-pencil icon_style"></i>}
                  labelText="Blog Heading"
                  name="heading"
                  placeText="Blog Heading"
                />
                <BannerImageCard
                  title="Banner Image"
                  imageUrl={imageUrl ?? BannerImage}
                  setImageFile={setImageFile}
                  setImageUrl={setImageUrl}
                  imageText="Change Image"
                  isBlogImage={true}
                />
                <div className="row">
                  <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <Selects
                      name="category_id"
                      labelText="Category"
                      placeText="e.g. Job Blog"
                      onSelect={(_, value) => {
                        setCategoryName(value.children)
                      }}
                      options={category}
                    />
                  </div>
                </div>
                <BaseInput
                  inputIcon={<i className="bi bi-pencil icon_style"></i>}
                  labelText="Permalink Generator"
                  name="permalink"
                  placeText="EX- Submit Blog Sitemap"
                />
                <BaseInput
                  inputIcon={<i className="bi bi-pencil icon_style"></i>}
                  labelText="Search Description"
                  name="search_description"
                  placeText="EX- Submit Blog Sitemap"
                />
                <TagSelect
                  labelText="Tags"
                  placeText="Search"
                  name="tag_ids"
                  options={category}
                // loading={skillsAutoCompleteLoading}
                // setAutoCompleteValue={setJobSkillSearchValue}
                />
              </div>
            </div>
            <div className="row px-3">
              <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                <TextEditor
                  labelText="Description"
                  setEditorData={setEditorData}
                  editorEditData={editorEditData}
                  setIsEditorEmpty={setIsEditorEmpty}

                />
              </div>
            </div>
          </div>
        </Form>
      </BlogWrapped>
    </>
  );
};

export default BlogPost;
