import React, { useEffect, useState, useRef } from "react";
import { Form, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { STATUSES } from "../../config/constants/defaultValues";
import {
  autoCompleteRejectAndAcceptRequest,
  getAutoCompleteApprovalRequest,
} from "../../redux/autoCompleteApprovalRequestSlice";
import RejectButton from "../button/rejectButton";
import SaveButton from "../button/saveButton";
import BaseInput from "../form/BaseInput";
import AutoCompleteModalCard from "../shared/AutoCompleteModalCard";
import Loading from "../shared/GeneralComponents";
import LoadingM from "../shared/GeneralComponentsModal";

const AutoCompleteModalWrapped = styled.div`
  background: #fcfbfb;
  border: 1px solid rgba(168, 168, 168, 0.09);
  border-radius: 12px;
  overflow: auto;
  overflow-x: hidden;
  height: 300px;
`;

const AutoCompleteModal = ({
  isModalOpen,
  setIsModalOpen,
  autoCompleteData,
  search,
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isReject, setIsReject] = useState(false);
  const [isEditable, setEditable] = useState(true);
  const isFocusElem = useRef()

  useEffect(() => {
    if (!isEditable) {
      isFocusElem.current?.focus();
    }
  }, [!isEditable])

  const {
    autoCompleteDetails,
    autoCompleteDetailsStatus,
    autoCompleteDetailsError,
  } = useSelector((state) => state.autoCompleteApproval);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (autoCompleteData) {
      form.setFieldsValue({
        value: autoCompleteData?.value,
      });
    }
  }, [autoCompleteData, form]);

  const onSave = (values) => {
    dispatch(
      autoCompleteRejectAndAcceptRequest({
        ...values,
        status: isReject ? 3 : 1,
        id: autoCompleteData.id,
      })
    ).then((value) => {
      dispatch(getAutoCompleteApprovalRequest({ generalSearch: search, page: pageNumber }));
    });
  };
  const setEditableField = () => {
    setEditable(!isEditable)
  };
  return (
    <>
      <Modal
        className="modal-styles"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={800}
        maskClosable={true}
        centered={true}
        title={false}
      >
        <Form layout="vertical" form={form} onFinish={onSave} >
          <div className="row">
            <div className="col-md-8">
              <BaseInput
                inputIcon={<i className="bi bi-pencil icon_style" style={{ color: !isEditable ? "#372354" : '#36245336' }} onClick={setEditableField}></i>}
                labelText={autoCompleteData?.model_name ?? ""}
                name="value"
                disabled={isEditable}
                placeText={autoCompleteData?.model_name ?? ""}
                isFocusElem={isFocusElem}
              />
            </div>
          </div>
          <div className="row" style={{ height: "445px" }}>
            <div className="col-md-12 mt-3">
              <p
                style={{
                  color: "#181818",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Related options are available in the system.
              </p>
            </div>
            {autoCompleteDetailsStatus === STATUSES.LOADING ? (
              <LoadingM
                isModal={false}
                isModalW={false}
                isModalCenter={false}
              />
            ) : autoCompleteDetailsStatus === STATUSES.ERROR ? (
              <div>{autoCompleteDetailsError}</div>
            ) : (
              <div className="col-md-12">
                <AutoCompleteModalCard
                  autoCompleteList={autoCompleteDetails ?? []}
                />
                <div>
                  <div className="d-flex justify-content-end mt-5">
                    <RejectButton
                      title="Reject"
                      onClick={() => setIsReject(true)}
                    />
                    <SaveButton title="Approve" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AutoCompleteModal;
