import styled from "styled-components";

const BlogWrapped = styled.div`
  padding: 0px 0px 0px 10px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
  .tab_section {
    margin-bottom: 15px;
    background: #f5f5f5;
    border: 0.8px solid #cccccc;
    border-radius: 5px;
    padding: 25px 36px;
    @media screen and (max-width: 768px) {
      padding: 20px 10px;
    }
    .blog_heading {
      font-weight: 700;
      font-size: 55px;
      color: #161616;
      line-height: 82px;
      word-break: break-all;
      @media screen and (max-width: 768px) {
        font-size: 26px;
        color: #161616;
        line-height: 40px;
      }
    }
    .tags_card {
      margin-top: 10px;
      padding: 6px 10px;
      background: rgba(51, 161, 97, 0.13);
      border: 0.5px solid #33a161;
      border-radius: 2px;
      margin-right: 15px;
      color: #363636;
      @media screen and (max-width: 568px) {
        margin-right: 0px;
        margin-top: 10px;
      }
    }
    /* ul li {
      height: 45px;
      width: 45px;
      background: rgb(12, 172, 98);
      display: flex;
      -webkit-box-pack: center;
      color: white;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      margin: 10px 0px;
      border-radius: 4px 4px 0px 0px;
    } */
    .share p {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 0px;
      color: rgba(51, 161, 97, 0.53);
    }
    .like_div {
      background: #372354;
      border-radius: 4px 0px 0px 4px;
      height: 45px;
      width: 45px;
      border-radius: 4px 4px 0px 0px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      cursor: pointer;
      -webkit-box-pack: center;
      color: white;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 10px 0px;
    }
    .copy_dev {
      font-weight: 400;
      font-size: 12px;
      color: rgba(55, 35, 84, 0.49);
    }
  }
`;
export { BlogWrapped };
