import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { CONTENT_PAGES, REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { CONTACT_PAGE_API, GET_CONTENT_MANAGEMENT_PAGES, HOME_PAGE_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";



// HomeContentPage Requests
export const getHomeContentPageContentRequest = createAsyncThunk(
    "contentManagement/getHomeContentPageRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                HOME_PAGE_API,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postHomeContentPageContentRequest = createAsyncThunk(
    "contentManagement/getHomeContentPageRequest",
    async (values, { rejectWithValue }) => {
        const newData = new FormData();
        for (var key in values) {
            newData.append(key, values[key])
        }

        try {
            const response = await apiService(
                HOME_PAGE_API,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// ContactPage Requests
export const getContactPageContentRequest = createAsyncThunk(
    "contentManagement/getContactPageRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_PAGE_API,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postContactPageContentRequest = createAsyncThunk(
    "contentManagement/getContactPageRequest",
    async (values, { rejectWithValue }) => {
        const newData = new FormData();
        for (var key in values) {
            newData.append(key, values[key])
        }
        try {
            const response = await apiService(
                CONTACT_PAGE_API,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message)

                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

// AboutUs Requests
export const getAboutUsContentRequest = createAsyncThunk(
    "contentManagement/getAboutUsContentRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.ABOUT_US,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postAboutUsContentRequest = createAsyncThunk(
    "contentManagement/getAboutUsContentRequest",
    async (values, { rejectWithValue }) => {
        const newData = new FormData();
        for (var key in values) {

            newData.append(key, values[key])
        }
        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.ABOUT_US,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message)
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// PicturePolicy Requests
export const getPicturePolicyContentRequest = createAsyncThunk(
    "contentManagement/getPicturePolicyContentRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.PICTURE_POLICY,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postPicturePolicyContentRequest = createAsyncThunk(
    "contentManagement/postPicturePolicyContentRequest",
    async (values, { rejectWithValue }) => {
        const newData = new FormData();
        for (var key in values) {
            newData.append(key, values[key])
        }

        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.PICTURE_POLICY,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message)
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// TermsOfService Requests
export const getTermsOfServiceContentRequest = createAsyncThunk(
    "contentManagement/getTermsOfServiceContentRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.TERMS_OF_SERVICE,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postTermsOfServiceContentRequest = createAsyncThunk(
    "contentManagement/postTermsOfServiceContentRequest",
    async (values, { rejectWithValue }) => {
        const newData = new FormData();
        for (var key in values) {
            newData.append(key, values[key])
        }

        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.TERMS_OF_SERVICE,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message)
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// PrivacyPolicy Requests
export const getPrivacyPolicyContentRequest = createAsyncThunk(
    "contentManagement/getPrivacyPolicyContentRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.PRIVACY_POLICY,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postPrivacyPolicyContentRequest = createAsyncThunk(
    "contentManagement/postPrivacyPolicyContentRequest",
    async (values, { rejectWithValue }) => {
        const newData = new FormData();
        for (var key in values) {
            newData.append(key, values[key])
        }
        try {
            const response = await apiService(
                GET_CONTENT_MANAGEMENT_PAGES + CONTENT_PAGES.PRIVACY_POLICY,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message)
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);


export const getBlogContentRequest = createAsyncThunk(
    "contentManagement/contentManagementData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_PAGE_API,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const contentManagementSlice = createSlice({
    name: "contentManagement",
    initialState: {
        homeContentPageData: null,
        homeContentPageStatus: STATUSES.IDLE,
        homeContentPageError: null,
        // ContactUs state
        contactPageData: null,
        contactPageStatus: STATUSES.IDLE,
        contactPageError: null,
        // AboutUs state
        aboutUsData: null,
        aboutUsStatus: STATUSES.IDLE,
        aboutUsError: null,
        // PicturePolicy state
        picturePolicyData: null,
        picturePolicyStatus: STATUSES.IDLE,
        picturePolicyError: null,
        // TermsOfService state
        termsOfServiceData: null,
        termsOfServiceStatus: STATUSES.IDLE,
        termsOfServiceError: null,
        // PrivacyPolicy state
        privacyPolicyData: null,
        privacyPolicyStatus: STATUSES.IDLE,
        privacyPolicyError: null,
    },
    extraReducers: {

        // HomeContent
        [getHomeContentPageContentRequest.pending]: (state, action) => {
            state.homeContentPageStatus = STATUSES.LOADING;
        },
        [getHomeContentPageContentRequest.fulfilled]: (state, action) => {

            state.homeContentPageStatus = STATUSES.IDLE;
            state.homeContentPageData = action.payload;
        },
        [getHomeContentPageContentRequest.rejected]: (state, action) => {
            state.homeContentPageStatus = STATUSES.ERROR;
            state.homeContentPageError = action.payload;
        },
        [postHomeContentPageContentRequest.pending]: (state, action) => {
            state.homeContentPageStatus = STATUSES.LOADING;
        },
        [postHomeContentPageContentRequest.fulfilled]: (state, action) => {

            state.homeContentPageStatus = STATUSES.IDLE;
            state.homeContentPageData = action.payload;
        },
        [postHomeContentPageContentRequest.rejected]: (state, action) => {
            state.homeContentPageStatus = STATUSES.ERROR;
            state.homeContentPageError = action.payload;
        },
        // ContactPage
        [getContactPageContentRequest.pending]: (state, action) => {
            state.contactPageStatus = STATUSES.LOADING;
        },
        [getContactPageContentRequest.fulfilled]: (state, action) => {

            state.contactPageStatus = STATUSES.IDLE;
            state.contactPageData = action.payload;
        },
        [getContactPageContentRequest.rejected]: (state, action) => {
            state.contactPageStatus = STATUSES.ERROR;
            state.contactPageError = action.payload;
        },
        [postContactPageContentRequest.pending]: (state, action) => {
            state.contactPageStatus = STATUSES.LOADING;
        },
        [postContactPageContentRequest.fulfilled]: (state, action) => {

            state.contactPageStatus = STATUSES.IDLE;
            state.contactPageData = action.payload;
        },
        [postContactPageContentRequest.rejected]: (state, action) => {
            state.contactPageStatus = STATUSES.ERROR;
            state.contactPageError = action.payload;
        },
        // AboutUs
        [getAboutUsContentRequest.pending]: (state, action) => {
            state.aboutUsStatus = STATUSES.LOADING;
        },
        [getAboutUsContentRequest.fulfilled]: (state, action) => {

            state.aboutUsStatus = STATUSES.IDLE;
            state.aboutUsData = action.payload;
        },
        [getAboutUsContentRequest.rejected]: (state, action) => {
            state.aboutUsStatus = STATUSES.ERROR;
            state.aboutUsError = action.payload;
        },
        [postAboutUsContentRequest.pending]: (state, action) => {
            state.aboutUsStatus = STATUSES.LOADING;
        },
        [postAboutUsContentRequest.fulfilled]: (state, action) => {

            state.aboutUsStatus = STATUSES.IDLE;
            state.aboutUsData = action.payload;
        },
        [postAboutUsContentRequest.rejected]: (state, action) => {
            state.aboutUsStatus = STATUSES.ERROR;
            state.aboutUsError = action.payload;
        },
        // picturePolicy
        [getPicturePolicyContentRequest.pending]: (state, action) => {
            state.picturePolicyStatus = STATUSES.LOADING;
        },
        [getPicturePolicyContentRequest.fulfilled]: (state, action) => {

            state.picturePolicyStatus = STATUSES.IDLE;
            state.picturePolicyData = action.payload;
        },
        [getPicturePolicyContentRequest.rejected]: (state, action) => {
            state.picturePolicyStatus = STATUSES.ERROR;
            state.picturePolicyError = action.payload;
        },
        [postPicturePolicyContentRequest.pending]: (state, action) => {
            state.picturePolicyStatus = STATUSES.LOADING;
        },
        [postPicturePolicyContentRequest.fulfilled]: (state, action) => {

            state.picturePolicyStatus = STATUSES.IDLE;
            state.picturePolicyData = action.payload;
        },
        [postPicturePolicyContentRequest.rejected]: (state, action) => {
            state.picturePolicyStatus = STATUSES.ERROR;
            state.picturePolicyError = action.payload;
        },
        // TermsOfService
        [getTermsOfServiceContentRequest.pending]: (state, action) => {
            state.termsOfServiceStatus = STATUSES.LOADING;
        },
        [getTermsOfServiceContentRequest.fulfilled]: (state, action) => {

            state.termsOfServiceStatus = STATUSES.IDLE;
            state.termsOfServiceData = action.payload;
        },
        [getTermsOfServiceContentRequest.rejected]: (state, action) => {
            state.termsOfServiceStatus = STATUSES.ERROR;
            state.termsOfServiceError = action.payload;
        },
        [postTermsOfServiceContentRequest.pending]: (state, action) => {
            state.termsOfServiceStatus = STATUSES.LOADING;
        },
        [postTermsOfServiceContentRequest.fulfilled]: (state, action) => {

            state.termsOfServiceStatus = STATUSES.IDLE;
            state.termsOfServiceData = action.payload;
        },
        [postTermsOfServiceContentRequest.rejected]: (state, action) => {
            state.termsOfServiceStatus = STATUSES.ERROR;
            state.termsOfServiceError = action.payload;
        },
        // PrivacyPolicy
        [getPrivacyPolicyContentRequest.pending]: (state, action) => {
            state.privacyPolicyStatus = STATUSES.LOADING;
        },
        [getPrivacyPolicyContentRequest.fulfilled]: (state, action) => {

            state.privacyPolicyStatus = STATUSES.IDLE;
            state.privacyPolicyData = action.payload;
        },
        [getPrivacyPolicyContentRequest.rejected]: (state, action) => {
            state.privacyPolicyStatus = STATUSES.ERROR;
            state.privacyPolicyError = action.payload;
        },
        [postPrivacyPolicyContentRequest.pending]: (state, action) => {
            state.privacyPolicyStatus = STATUSES.LOADING;
        },
        [postPrivacyPolicyContentRequest.fulfilled]: (state, action) => {

            state.privacyPolicyStatus = STATUSES.IDLE;
            state.privacyPolicyData = action.payload;
        },
        [postPrivacyPolicyContentRequest.rejected]: (state, action) => {
            state.privacyPolicyStatus = STATUSES.ERROR;
            state.privacyPolicyError = action.payload;
        },
    },
});
export default contentManagementSlice.reducer;

