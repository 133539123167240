import { createGlobalStyle } from "styled-components";
import theme from "./config/theme/themeVariables";

const GlobalStyle = createGlobalStyle`
    *,*::before,*::after{
        margin: 0;
        padding: 0;
    }
    /* h1, h2, h3, h4, h5, h6, p{
        color: black;
    } */
    body {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        overflow-x: hidden;
        background: #F5F5F5;
    }
    .primary_color {
        color: ${theme.primaryColor};
    }
    .secondary_color {
        color: ${theme.secondaryColor};
    }
    .text_light_color {
        color: ${theme.textColors.textLightColor};
    }
    .text_lightest_color {
        color: ${theme.textColors.textLightestColor};
    }
    .text_lightGray_color {
        color: ${theme.textColors.lightGrayColor};
    }
    .radiu_base {
        border-radius: ${theme.shape.radiusBase};
    }
    .border_color {
        border: 1px solid ${theme.border.color};
    }
    .ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600 !important;
    }
    .ant-image {
        height: 100%;
        width: 100%;
    }
    .ant-input:hover,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,.ant-select:not(
    .ant-select-disabled):hover .ant-select-selector,
    .ant-picker:hover, .ant-picker-focused {
        box-shadow: unset !important;
        border-color: #909090 !important;
    }
    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        border-color: #ff4d4f !important;
    }
    
`;
export default GlobalStyle;
