import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import TextEditor from "../../components/TextEditor/TextEditor";
import BannerImage from "../../assets/images/bannerImage.png";
import { ContentWrapped } from "./style";
import BannerImageCard from "../../components/shared/bannerImageCard";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUsContentRequest, postAboutUsContentRequest } from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { STATUSES } from "../../config/constants/defaultValues";

const AboutUs = () => {
  const dispatch = useDispatch();
  const { aboutUsData, aboutUsStatus, aboutUsError } = useSelector(state => state.contentManagement)
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [imageFile, setImageFile] = useState();
  const [editorData, setEditorData] = useState();
  const [editorEditData, setEditorEditData] = useState();
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);


  useEffect(() => {
    dispatch(getAboutUsContentRequest())

  }, [dispatch]);
  useEffect(() => {
    if (aboutUsData) {
      form.setFieldsValue({
        heading: aboutUsData?.page_content.heading,
        bread_crumbs: aboutUsData?.page_content.bread_crumbs,
        description: aboutUsData?.page_content.description,
        email: aboutUsData?.page_content.email,
        email_pre_text: aboutUsData?.page_content.email_pre_text,
      });
      setImageUrl(aboutUsData?.page_content?.banner_media ?? BannerImage)
      setEditorEditData(aboutUsData?.page_content.description)
    }
  }, [aboutUsData, form])
  const onSave = (values) => {
    dispatch(postAboutUsContentRequest({
      ...values,
      banner_media: imageFile ?? null,
      description: editorData,
      id: aboutUsData?.id,
      page_content_id: aboutUsData?.page_content.id,
      title: aboutUsData?.title,
      _method: 'PUT'
    }))

  }
  return (
    <>
      {aboutUsStatus === STATUSES.LOADING ? <Loading />
        : aboutUsStatus === STATUSES.ERROR ? <div>{aboutUsError}</div> :
          <ContentWrapped>
            <div className="row">
              <div className="col-sm-12">
                <Form layout="vertical" className="pt-3" form={form} onFinish={onSave}>
                  <div className="row">
                    <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                      <h3 className="font-bold" >About Us</h3>
                      <SaveButton title="Save" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                      <BaseInput
                        inputIcon={<i className="bi bi-pencil icon_style"></i>}
                        labelText="Page Heading"
                        name="heading"
                        placeText="About Jump Recruiter"
                      />
                      <BaseInput
                        inputIcon={<i className="bi bi-pencil icon_style"></i>}
                        labelText="Bread Crumbs"
                        name="bread_crumbs"
                        placeText="Bread Crumbs"
                      />

                      <BaseInput
                        inputIcon={<i className="bi bi-pencil icon_style"></i>}
                        labelText="Email Pre Text"
                        name="email_pre_text"
                        placeText="Email Pre Text"
                      />
                      <BaseInput
                        inputIcon={<i className="bi bi-pencil icon_style"></i>}
                        labelText="Email"
                        name="email"
                        inputType="email"
                        placeText="Email"
                      />
                      <BannerImageCard
                        title="Banner Image"
                        imageUrl={imageUrl}
                        setImageFile={setImageFile}
                        setImageUrl={setImageUrl}
                        imageText="Change Image"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                      <TextEditor
                        labelText="Description"
                        setEditorData={setEditorData}
                        editorEditData={editorEditData}
                        setIsEditorEmpty={setIsEditorEmpty}

                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </ContentWrapped>}
    </>
  );
};

export default AboutUs;
