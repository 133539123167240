import React from "react";
import { Select, Form, Spin, Empty } from "antd";
import styled from "styled-components";
const MultiSelectInputWrapped = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
    background: #f7f7f7;
    border: 0.5px solid #b8b8b8;
    border-radius: 5px;
  }
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
`;

const { Option } = Select;

const TagSelect = ({
  placeText,
  labelText,
  options,
  name,
  sizes,
  onSearch,
  onSelect,
  onDeselect,
  onChange,
  loading,
  marginBottom,
}) => {
  return (
    <MultiSelectInputWrapped>
      <Form.Item
        name={name || "name"}
        label={labelText || ""}
        className={marginBottom ? `${marginBottom} w-100` : "w-100 mb-4"}
        rules={[
          {
            required: true,
            message: "This field is required!!",
          },
        ]}
      >
        <Select
          placeholder={placeText || "Select Here"}
          size={sizes || "middle"}
          mode="tags"
          onSearch={onSearch}
          onSelect={onSelect}
          onDeselect={onDeselect}
          onChange={onChange}
          notFoundContent={loading ? <Spin /> : <Empty />}
          maxTagCount="responsive"
          style={{ height: "56px" }}
        >
          {options?.map((item) => {
            return (
              <Option key={item?.id} value={item?.name}>
                {item?.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </MultiSelectInputWrapped>
  );
};

export default TagSelect;
