import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AutoCompletes from "../../components/form/AutoComplete";
import BaseInput from "../../components/form/BaseInput";
import { STATUSES, TableDataState } from "../../config/constants/defaultValues";
import {
  getBogAutocompleteDataRequest,
  updateBlogManagementRequest,
} from "../../redux/blogSlice";
import { SystemConfigurationWrapped } from "../system/style";

const BlogConfigurationDetail = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [modifyData, setModifyData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [lastIndex, setLastIndex] = useState(1000);

  const searchInput = useRef(null);
  const {
    blogManagementDetailData,
    blogManagementDetailStatus,
    blogManagementDetailError,
  } = useSelector((state) => state.blog);

  useEffect(() => {
    if (blogManagementDetailData?.data?.blog_page_section_content) {
      setData(blogManagementDetailData.data.blog_page_section_content);
      setModifyData([]);
      setLastIndex(
        (blogManagementDetailData.data.blog_page_section_content[
          blogManagementDetailData.data.blog_page_section_content.length - 1
        ]?.id ?? 0) + 1
      );
    }
  }, [blogManagementDetailData?.data?.blog_page_section_content]);

  const onSubmit = () => {
    dispatch(
      updateBlogManagementRequest({
        values: {
          id: blogManagementDetailData.data.id,
          title: blogManagementDetailData.data.title,
          status: 1,
          collection_list: modifyData,
        },
        blogID: blogManagementDetailData.data.id,
      })
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      value: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const onChangePage = (page) => {
    setEditingKey("");
    setCurrentPage(page);
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      console.log("rowdata", row);
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      const newRow = {
        blog_title: row.blog_title[0],
        blog_id: row.blog_title[1],
      };

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...newRow,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(newRow);
        setData(newData);
        setEditingKey("");
      }

      const modifiedData = [...modifyData];
      const indexInModify = modifiedData.findIndex((item) => id === item.id);
      const indexInMainList =
        blogManagementDetailData.data.blog_page_section_content.findIndex(
          (item) => id === item.id
        );

      if (indexInModify > -1 && indexInMainList > -1) {
        const item = modifiedData[indexInModify];

        modifiedData.splice(indexInModify, 1, {
          ...item,
          ...newRow,
          state: TableDataState.UPDATE,
          status: 1,
        });
        setModifyData(modifiedData);
      } else if (indexInModify > -1) {
        const item = modifiedData[indexInModify];

        modifiedData.splice(indexInModify, 1, {
          ...item,
          ...newRow,
          state: TableDataState.CREATE,
          status: 1,
        });
        setModifyData(modifiedData);
      } else {
        if (indexInMainList > -1) {
          modifiedData.push({
            ...newRow,
            id: id,
            state: TableDataState.UPDATE,
            status: 1,
          });
          setModifyData(modifiedData);
        } else {
          modifiedData.push({
            ...newRow,
            id: id,
            state: TableDataState.CREATE,
            status: 1,
          });
          setModifyData(modifiedData);
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
            width: 300,
          }}
        />
        <Space className="d-flex flex-row-reverse">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);

    const modifiedData = [...modifyData];

    const indexInModify = modifyData.findIndex((item) => id === item.id);
    const index =
      blogManagementDetailData.data.blog_page_section_content.findIndex(
        (item) => id === item.id
      );

    if (indexInModify > -1 && index > -1) {
      const item = modifiedData[indexInModify];
      modifiedData.splice(indexInModify, 1, {
        ...item,
        state: TableDataState.DELETE,
        status: 1,
      });
      setModifyData(modifiedData);
    } else if (indexInModify > -1) {
      const newData = modifiedData.filter((item) => item.id !== id);
      setModifyData(newData);
    } else {
      if (index > -1) {
        const item =
          blogManagementDetailData.data.blog_page_section_content[index];
        setModifyData([
          ...modifiedData,
          {
            ...item,
            state: TableDataState.DELETE,
            status: 1,
          },
        ]);
      }
    }
  };

  const handleAdd = () => {
    const newData = {
      id: lastIndex,
    };
    setData([...data, newData]);
    setLastIndex(lastIndex + 1);
    edit(newData);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "15%",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Blog Name",
      dataIndex: "blog_title",
      editable: true,
      ellipsis: true,
      ...getColumnSearchProps("blog_title"),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <CheckCircleOutlined
              onClick={() => save(record.id)}
              style={{
                marginRight: 12,
                color: "green",
                fontSize: "22px",
              }}
            />

            <CloseCircleOutlined
              style={{
                color: "red",
                fontSize: "22px",
              }}
              onClick={cancel}
            />
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              className="mr-5"
            >
              Edit
            </Typography.Link>

            {/* <Popconfirm
              className="ml-5"
              Popconfirm
              title="Are you sure you want to delete this?"
              placement="top"
              onConfirm={() => handleDelete(record.id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm> */}
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <SystemConfigurationWrapped>
        <div className="row">
          <div className=" justify-content-between ">
            {/* <BaseInput
              inputIcon={<i className="bi bi-pencil icon_style"></i>}
              name="apply_for_job_heading"
              placeText="Page"
            /> */}
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-end pb-3">
                {/* <Link className="reject_btn" to="#" onClick={handleAdd}
                >
                  Add
                </Link> */}
                <Link className="approved_btn" to="#" onClick={onSubmit}>
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {/* <div className="tab_section"> */}
            {/* <div className="table-responsive"> */}
            {/* <Table dataSource={dataSource} columns={columns} /> */}
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={
                  blogManagementDetailStatus === STATUSES.ERROR ? [] : data
                }
                columns={mergedColumns}
                loading={
                  blogManagementDetailStatus === STATUSES.LOADING ? true : false
                }
                rowClassName="editable-row"
                bordered={true}
                indentSize={33}
                pagination={{
                  onChange: onChangePage,
                  current: currentPage,
                }}
              />
            </Form>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </SystemConfigurationWrapped>
    </>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const [blogAutoCompleteSearch, setBlogAutoCompleteSearch] = useState("");
  const { blogAutoCompleteData, blogAutoCompleteStatus } = useSelector(
    (state) => state.blog
  );
  const dispatch = useDispatch();

  // Autocomplete search with delay
  useEffect(() => {
    if (blogAutoCompleteSearch) {
      dispatch(
        getBogAutocompleteDataRequest({ search: blogAutoCompleteSearch })
      );
    }
  }, [blogAutoCompleteSearch]);

  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <>
          <AutoCompletes
            placeText="Search Blog"
            name={dataIndex}
            options={blogAutoCompleteData}
            loading={blogAutoCompleteStatus === STATUSES.LOADING}
            setAutoCompleteValue={setBlogAutoCompleteSearch}
            marginBottom="mb-0"
          />
        </>
      ) : (
        children
      )}
    </td>
  );
};

export default BlogConfigurationDetail;
