import React from "react";
import { Select, Form } from "antd";
import styled from "styled-components";
const SelectInputWrapped = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f7f7f7;
    border: 0.5px solid #b8b8b8;
    border-radius: 5px;
    height: 40px;
  }
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    background: #f7f7f7;
    height: 38px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
  }
`;
const { Option } = Select;

const Selects = ({
  placeText,
  labelText,
  options,
  name,
  req,
  sizes,
  mode,
  marginBottom,
  onSelect,
  maxLength,
}) => {
  return (
    <SelectInputWrapped>
      <Form.Item
        name={name || "name"}
        label={labelText || ""}
        className={marginBottom ? `${marginBottom} w-100` : "w-100 mb-4"}
        rules={[
          {
            required: req === false ? false : true,
            message: "This field is required!!",
          },
        ]}
      >
        <Select
          allowClear
          showSearch
          placeholder={placeText || "Select Here"}
          size={sizes || "middle"}
          mode={mode || ""}
          onSelect={onSelect}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          maxLength={maxLength || 50}
        >
          {options?.map((optionsData, index) => (
            <Option key={index} value={optionsData?.id}>
              {optionsData?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </SelectInputWrapped>
  );
};

export default Selects;
