import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { JOB_APPROVAL_API, JOB_APPROVAL_Request_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getJobApprovalRequestDataRequest = createAsyncThunk(
    "jobApprovalRequest/jobApprovalRequestData",
    async ({ generalSearch, page }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                JOB_APPROVAL_API,
                REQUEST_METHOD.POST,
                {
                    "sort": { "field": "id", "sort": "asc" },
                    "pagination": { "page": page ?? 1, "perpage": "10" },
                    "query": { "status": 3, "generalSearch": generalSearch ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const jobApprovalRejectAndAcceptRequest = createAsyncThunk(
    "jobApprovalRequest/jobApprovalRejectRequest",
    async ({ jobID, jobStatus }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                JOB_APPROVAL_Request_API,
                REQUEST_METHOD.POST,
                {
                    "job_id": jobID,
                    "status": jobStatus,

                },
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message)
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getJobDetailsByIdRequest = createAsyncThunk(
    "jobApprovalRequest/jobDetails",
    async ({ jobID }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                `/jobs/${jobID}/detail`,
                REQUEST_METHOD.GET,
                {},
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const JobApprovalRequestSlice = createSlice({
    name: "jobApprovalRequest",
    initialState: {
        jobApprovalData: null,
        jobDetails: null,
        status: STATUSES.IDLE,
        error: null,
    },
    extraReducers: {
        [getJobApprovalRequestDataRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getJobApprovalRequestDataRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.jobApprovalData = action.payload;
        },
        [getJobApprovalRequestDataRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [jobApprovalRejectAndAcceptRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;

        },
        [jobApprovalRejectAndAcceptRequest.fulfilled]: (state, action) => {
            state.status = STATUSES.IDLE;


        },
        [jobApprovalRejectAndAcceptRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [getJobDetailsByIdRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;

        },
        [getJobDetailsByIdRequest.fulfilled]: (state, action) => {
            state.status = STATUSES.IDLE;
            state.jobDetails = action.payload;


        },
        [getJobDetailsByIdRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
    },
});
export default JobApprovalRequestSlice.reducer;

