import { Button } from "antd";
import React, { useEffect } from "react";
import JobApprovedTile from "../components/shared/JobApprovedTile";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobDetailsByIdRequest,
  jobApprovalRejectAndAcceptRequest,
} from "../redux/jobApprovalRequestSlice";
import { useNavigate, useParams } from "react-router-dom";
import { TabPanelDetailStyle } from "./style";
import { STATUSES } from "../config/constants/defaultValues";
import Loading from "../components/shared/GeneralComponents";

const JobRequestDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobID } = useParams();

  const { status, jobDetails } = useSelector(
    (state) => state.jobApprovalRequest
  );

  useEffect(() => {
    dispatch(getJobDetailsByIdRequest({ jobID: jobID }));
  }, [dispatch, jobID]);

  const onReject = (jobStatus) => {
    dispatch(
      jobApprovalRejectAndAcceptRequest({ jobID: jobID, jobStatus: jobStatus })
    ).then(() => {
      navigate(-1);
    });
  };
  return (
    <>
      {status === STATUSES.LOADING ? (
        <Loading />
      ) : status === STATUSES.ERROR ? (
        <div tip="Error" />
      ) : (
        <TabPanelDetailStyle>
          <div className="row w-100 mr-0">
            <div className="col-sm-12 justify-content-between d-flex align-items-center px-0 ">
              <div>
                <div className="padding-left-6 d-flex align-items-center">
                  <div
                    className="heading"
                    style={{
                      color: "#372354",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Job Approval Request
                  </div>
                  <div className="heading px-3" style={{ fontSize: "16px" }}>
                    {" "}
                    &#62;
                  </div>
                  <div className="heading_child " style={{ fontSize: "16px" }}>
                    {jobDetails?.job_title?.name ?? " "}
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {" "}
                <Button
                  type="button"
                  className="reject_btn"
                  onClick={() => onReject(4)}
                >
                  Reject
                </Button>{" "}
                <Button
                  type="button"
                  className="approved_btn"
                  onClick={() => onReject(1)}
                >
                  Approve
                </Button>
              </div>
            </div>
          </div>
          <div className="section-style">
            <div className="row space-between w-100">
              <div className="d-flex align-items-center">
                <JobApprovedTile
                  title="Experience"
                  subTitle={jobDetails?.experience_year.name ?? "Not Provide"}
                />
                <JobApprovedTile
                  title="Work Level"
                  subTitle={jobDetails?.carrier_level.name ?? "Not Provide"}
                />
                <JobApprovedTile
                  title="Employee Type"
                  subTitle={jobDetails?.job_types?.map((jobTypeData) => (
                    <>{jobTypeData.job_type.name + "  " ?? "Not Provide"}</>
                  ))}
                />
                <JobApprovedTile
                  title="Offer Salary"
                  subTitle={
                    <>
                      {jobDetails?.currency_symbol
                        ? jobDetails?.currency_symbol
                        : " USD"}{" "}
                      {jobDetails?.salary_range}
                      {"/"}
                      {jobDetails?.salary_period?.name
                        ? jobDetails?.salary_period?.name
                        : " Yearly"}
                    </>
                  }
                />
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-12 px-2">
                <h6 className="heading-title">Job Description</h6>
                <h6 className="test-p text-break">
                  {ReactHtmlParser(jobDetails?.description ?? "")}
                </h6>
              </div>
            </div>

            <div className="row space-between">
              <div className="col-12 px-2">
                <h4 className="heading-title">Job Skills</h4>
                <ul className="jobs__skillsUl">
                  {jobDetails?.job_skills?.map((item, key) => (
                    <li key={key}>{item?.job_skill?.name}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="row space-between">
              <div className="col-12 px-2">
                <h4 className="heading-title">Job Details</h4>
              </div>
              <div className="col-12 px-2">
                <ul
                  className="jobs_detailUl"
                  style={{ listStyle: "none", paddingLeft: "0px" }}
                >
                  <div>
                    <li>Industry</li>
                    <li>:</li>
                    <li>{jobDetails?.industry?.name}</li>
                  </div>
                  <div>
                    <li>Functional Area</li>
                    <li>:</li>
                    <li>{jobDetails?.functional_area?.name}</li>
                  </div>
                  <div>
                    <li>Total Positions</li>
                    <li>:</li>
                    <li>{jobDetails?.hiring_position?.name}</li>
                  </div>
                  <div>
                    <li>Job Shift</li>
                    <li>:</li>
                    <li>
                      {jobDetails?.job_schedule_jobs?.map(
                        (item) => item?.job_schedule_jobDetails?.name
                      )}
                    </li>
                  </div>
                  <div>
                    <li>Job Type</li>
                    <li>:</li>
                    <li>
                      {jobDetails?.job_types?.map(
                        (item) => item?.job_type?.name + ' '
                      )}
                    </li>
                  </div>
                  <div>
                    <li>Job Location</li>
                    <li>:</li>
                    <li>
                      {jobDetails?.job_locations?.map(
                        (item) => item?.location?.location + ' '
                      )}
                    </li>
                  </div>
                  <div>
                    <li>Job Schedule</li>
                    <li>:</li>
                    <li>
                      {jobDetails?.job_schedule_jobs?.map(
                        (item) => item?.job_schedule_job?.name + ' '
                      )}
                    </li>
                  </div>
                  <div>
                    <li>Gender</li>
                    <li>:</li>
                    <li>{jobDetails?.gender?.name}</li>
                  </div>
                  <div>
                    <li>Minimum Education</li>
                    <li>:</li>
                    <li>
                      {jobDetails?.job_educations?.map(
                        (item) => item?.job_education?.name + ' '
                      )}
                    </li>
                  </div>
                  <div>
                    <li>Career Level</li>
                    <li>:</li>
                    <li>{jobDetails?.carrier_level?.name}</li>
                  </div>
                  <div>
                    <li>Minimum Experience</li>
                    <li>:</li>
                    <li>{jobDetails?.experience_year?.name}</li>
                  </div>
                  <div>
                    <li>Apply Before</li>
                    <li>:</li>
                    <li>{jobDetails?.closing_date}</li>
                  </div>
                  <div>
                    <li>Urgently Hiring</li>
                    <li>:</li>
                    <li>
                      {jobDetails?.urgently_hiring?.name}
                    </li>
                  </div>
                  <div>
                    <li>Posting Date</li>
                    <li>:</li>
                    <li>{jobDetails?.opening_date}</li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </TabPanelDetailStyle>
      )}
    </>
  );
};
export default JobRequestDetail;
